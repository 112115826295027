

import {ApiService} from "./ApiService"
import ErrorService from '../services/ErrorService'
import api from '../config/api'

class BoardService extends ApiService {

    // company ----------------------------------------------------
    async getCategory(token, key) {
        const url = `${api.board.category}/${key}`
        const res = await this.apiCall(url, 'get', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getCategories(token, params) {
        const res = await this.apiCall(api.board.category, 'get', token, null, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    // tags ----------------------------------------------------
    async getTags(token) {
        const res = await this.apiCall(api.board.tag, 'get', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }
    // company ----------------------------------------------------

    async getCompany(token, key) {
        const url = `${api.board.company}/${key}`
        const res = await this.apiCall(url, 'GET', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getCompanies(token, params) {
        const res = await this.apiCall(api.board.company, 'GET', token, null, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async createCompany(token, params) {
        const res = await this.apiCall(api.board.company, 'post', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async updateCompany(token, key, params) {
        const url = `${api.board.company}/${key}`
        const res = await this.apiCall(url, 'patch', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async destroyCompany(token, key) {
        const url = `${api.board.company}/${key}`
        const res = await this.apiCall(url, 'delete', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }


    async getCategoryCompanies(token, key) {
        const url = `${api.board.category}/${key}/companies`
        const res = await this.apiCall(url, 'GET', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getCompanies(token, params) {
        const res = await this.apiCall(api.board.company, 'GET', token, null, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    // item ----------------------------------------------------

    async getItem(token, key) {
        const url = `${api.board.item}/${key}`
        const res = await this.apiCall(url, 'GET', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getItems(token, params) {
        const res = await this.apiCall(api.board.item, 'GET', token, null, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getAllItems(token, params) {
        const url = `${api.board.item}/all_index`
        const res = await this.apiCall(url, 'GET', token, null, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getCategoryItems(token, key) {
        const url = `${api.board.category}/${key}/items`
        const res = await this.apiCall(url, 'GET', token, null);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getCategoryAllItems(token, key, params) {
        const url = `${api.board.category}/${key}/all_items`
        const res = await this.apiCall(url, 'GET', token, null, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getCompanyItems(token, key) {
        const url = `${api.board.company}/${key}/items`
        const res = await this.apiCall(url, 'GET', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async createItem(token, params) {
        const res = await this.apiCall(api.board.item, 'post', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async updateItem(token, key, params) {
        const url = `${api.board.item}/${key}`
        const res = await this.apiCall(url, 'patch', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async destroyItem(token, key) {
        const url = `${api.board.item}/${key}`
        const res = await this.apiCall(url, 'delete', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async searchItem(token, params) {
        const url = `${api.board.item}/search`
        const res = await this.apiCall(url, 'post', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }


    // item ----------------------------------------------------
    async getRecord(token, key) {
        const url = `${api.board.record}/${key}`
        const res = await this.apiCall(url, 'get', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getRecords(token) {
        const res = await this.apiCall(api.board.record, 'get', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async createRecord(token, params) {
        const res = await this.apiCall(api.board.record, 'post', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getItemRecords(token, key) {
        const url = `${api.board.item}/${key}/records`
        const res = await this.apiCall(url, 'get', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async updateRecord(token, key, params) {
        const url = `${api.board.record}/${key}`
        const res = await this.apiCall(url, 'put', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async destroyRecord(token, key) {
        const url = `${api.board.record}/${key}`
        const res = await this.apiCall(url, 'delete', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async exportCsv(token, params) {
        const url = `${api.board.item}/export_csv`
        const res = await this.apiCall(url, 'get', token, null, params)
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    // comment -----------------------------------------------------------------
    async getComments(token, key) {
        const url = `${api.board.record}/${key}/comments`
        const res = await this.apiCall(url, 'get', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async createComment(token, params) {
        const res = await this.apiCall(api.board.comment, 'post', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async destroyComment(token, key) {
        const url = `${api.board.comment}/${key}`
        const res = await this.apiCall(url, 'delete', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

}

export default new BoardService()