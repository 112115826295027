import React from 'react'
import classNames from 'classnames'
import queryString from 'query-string'

import {
    PageHeader,
} from 'antd'

import RecordEditForm from '../../../components/EditForm/record'


export default class RecordNew extends React.Component {
    render() {
        const parsed = queryString.parse(this.props.location.search)
        const itemId = parsed.item ? parsed.item : null
        return (
            <div className={classNames("small-content")}>
                <PageHeader
                    // onBack={() => null}
                    title="新建记录"
                    subTitle="新建我的记录"
                />
                <div className={classNames("content-info", "small-list")}>
                    <RecordEditForm itemId={itemId}/>
                </div>
            </div>
        )
    }
}