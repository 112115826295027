import React from 'react';
import moment from 'moment';
import {
    Table,
    Card,
    Tag,
    Button
} from 'antd'

import history from '../../router/history'
import config from '../../config'
import ItemTag from '../LinkTag/item'
const { Column } = Table;

export default class CompanyItems extends React.Component {

    handleRecordClick = (e,key) => {
        e.preventDefault()
        history.push("/dashboard/records_new?item="+key)
    }

    handleEditClick = (e,key) => {
        e.preventDefault()
        history.push("/dashboard/items/"+key+"/edit")
    }

    handleNewClick = (e) => {
        e.preventDefault()
        history.push("/dashboard/items_new?company="+this.props.companyId)
    }

    render() {
        const items = this.props.items || []

        return (
            <div style={{width: 900}}>
                <Card bordered={false}>
                    <div>
                        <Button icon="plus" type="primary" onClick={this.handleNewClick}>
                            新建
                        </Button>
                    </div>
                    <div style={{marginTop: 10}}>
                        <Table 
                            dataSource={items.slice()}
                            rowKey={record=>record.id}
                        >
                            <Column
                                title="姓名"
                                key="name"
                                width={450}
                                render={(data, record) => {
                                    return (
                                        <ItemTag key={record.id} item={record}/>
                                    )
                                }}
                            />
                            <Column
                                title="负责人"
                                key="auth_users"
                                width={100}
                                render={(data, record) => {
                                    const users = record.users || []
                                    return (
                                        users.map(user=><Tag key={user.key}>{user.label}</Tag>)
                                    )
                                }}
                            />
                            <Column
                                title="距离上次访问"
                                key="latest_record"
                                width={150}
                                render={(data, record) => {
                                    return (
                                        <span>{record.latest_record ? moment(record.latest_record).fromNow() : "最近没有访问"}</span>
                                    )
                                }}
                            />
                            <Column
                                title="Action"
                                key="action"
                                render={(data) => (
                                    <span>
                                        <a href='javascript:;' onClick={(e)=>this.handleRecordClick(e, data.id)}>新增记录</a>
                                    </span>
                                )}
                            />
                        </Table>
                    </div>
                    共{items.slice().length}位客户
                </Card>
            </div>
        )
    }
}