
import {ApiService} from "./ApiService"
import ErrorService from '../services/ErrorService'
import api from '../config/api'

class AuthService extends ApiService {

    async getAuthItems(token, params) {
        const url = `${api.auth_list.item}`
        const res = await this.apiCall(url, 'GET', token, null, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async addAuth(token, params) {
        const url = `${api.auth_list.item}/add_auth`
        const res = await this.apiCall(url, 'post', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async removeAuth(token, params) {
        const url = `${api.auth_list.item}/remove_auth`
        const res = await this.apiCall(url, 'post', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async moveAuth(token, params) {
        const url = `${api.auth_list.item}/move_auth`
        const res = await this.apiCall(url, 'post', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }



    

}

export default new AuthService()