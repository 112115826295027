
import {ApiService} from "./ApiService"
import ErrorService from './ErrorService'
import api from '../config/api'

class AdminService extends ApiService {

    async createTag(token, params) {
        const res = await this.apiCall(api.admin.tag, 'post', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getTag(token, key) {
        const url = `${api.admin.tag}/${key}`
        const res = await this.apiCall(url, 'get', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async updateTag(token, key, params) {
        const url = `${api.admin.tag}/${key}`
        const res = await this.apiCall(url, 'put', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async destroyTag(token, key) {
        const url = `${api.admin.tag}/${key}`
        const res = await this.apiCall(url, 'delete', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async createCategory(token, params) {
        const res = await this.apiCall(api.admin.category, 'post', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getCategory(token, key) {
        const url = `${api.admin.category}/${key}`
        const res = await this.apiCall(url, 'get', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async updateCategory(token, key, params) {
        const url = `${api.admin.category}/${key}`
        const res = await this.apiCall(url, 'put', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async destroyCategory(token, key) {
        const url = `${api.admin.category}/${key}`
        const res = await this.apiCall(url, 'delete', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }
    
    async getManagerItem(token, key) {
        const url = `${api.admin.item}/${key}`
        const res = await this.apiCall(url, 'GET', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getManagerItems(token) {
        const url = `${api.admin.item}`
        const res = await this.apiCall(url, 'GET', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async exportCsv(token, params) {
        const url = `${api.admin.item}/export_csv`
        const res = await this.apiCall(url, 'get', token, null, params)
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getManagerCategoryItems(token, key, params) {
        const url = `${api.admin.category}/${key}/items`
        const res = await this.apiCall(url, 'GET', token, null, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getManagerRecords(token, params) {
        const url = `${api.admin.record}`
        const res = await this.apiCall(url, 'get', token, null, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getCategoryManagerRecords(token, key, params) {
        const url = `${api.admin.category}/${key}/records`
        const res = await this.apiCall(url, 'get', token, null, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getManagerItemRecords(token, key) {
        const url = `${api.admin.item}/${key}/records`
        const res = await this.apiCall(url, 'get', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getItemLogs(token, key) {
        const url = `${api.admin.item}/${key}/logs`
        const res = await this.apiCall(url, 'get', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getCompanyLogs(token, key) {
        const url = `${api.admin.company}/${key}/logs`
        const res = await this.apiCall(url, 'get', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async getManagerComments(token, key) {
        const url = `${api.admin.record}/${key}/comments`
        const res = await this.apiCall(url, 'get', token);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    async createManagerComment(token, params) {
        const res = await this.apiCall(api.admin.comment, 'post', token, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }


}

export default new AdminService()