import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import queryString from 'query-string'
import { inject, observer } from 'mobx-react'

import　history from '../../../router/history'

import { 
    Card, 
    List,
    PageHeader,
    Descriptions,
    Tag
} from 'antd';

import RecordDate from '../../../components/LinkTag/record_date'
import UserTag from '../../../components/LinkTag/user'
import ItemTag from '../../../components/LinkTag/item'

const CheckableTag = Tag.CheckableTag;

@inject('record', 'manager', 'user')
@observer
export default class RecordList extends React.Component {

    state = {
        user: null,
        category: null,
    };

    componentDidMount() {
        this.props.user.getUsers()
        this.props.manager.getCategories()
        const parsed = queryString.parse(this.props.location.search)
        this.setState(parsed)
        this.props.record.getManagerRecords(parsed)
    }

    handleShowClick = (e, key) => {
        e.preventDefault();
        history.push(`/dashboard/manager/records/${key}`)
    }

    handleChange = async (name, tag, checked) => {
        const params = {}
        if(checked){
            params[name] = tag
        } else {
            params[name] = null
        }
        await this.setState(params)
        const { category, user } = this.state
        this.props.record.getManagerRecords({category: category, user: user})
    }

    render() {
        const { users } = this.props.user
        const { categories } = this.props.manager
        const { category, user } = this.state;
        const { manager_records } = this.props.record

        return (
            <div className={classNames("small-content")}>
                <PageHeader
                    title="记录列表"
                    subTitle="全部记录"
                />
                <div className={classNames("content-info", "small-list")}>
                    <Card className={"record-filter"}>
                        <div className="category">
                            <h4>负责人:</h4>
                            <div>
                                {users.map(user_obj => (
                                    <CheckableTag
                                        key={user_obj.id}
                                        checked={user == user_obj.id ? !!user_obj : false}
                                        onChange={checked => this.handleChange("user", user_obj.id, checked)}
                                    >
                                        {user_obj.nickname}
                                    </CheckableTag>
                                ))}
                            </div>
                        </div>
                        <div className="category">
                            <h4>分类:</h4>
                            <div>
                                {categories.map(tag => (
                                    <CheckableTag
                                        key={tag.id}
                                        checked={category == tag.id ? !!category : false}
                                        onChange={checked => this.handleChange("category", tag.id, checked)}
                                    >
                                        {tag.name}
                                    </CheckableTag>
                                ))}
                            </div>
                        </div>
                    </Card>

                    <Card className={"record-list"}>
                        <List
                            className={"record-list"}
                            itemLayout="vertical"
                            dataSource={manager_records}
                            pagination={{ pageSize: 600 }}
                            renderItem={record => {
                            const users = record.users|| []
                            return (
                                <List.Item
                                    key={record.id}
                                    actions={[
                                        <a href='javascript:;' onClick={(e)=>this.handleShowClick(e, record.id)}>详细</a>,
                                    ]}
                                >
                                    <List.Item.Meta 
                                        title={
                                            <div>
                                                <div className={"record_items"}>
                                                    {
                                                        record.items.map(item=>(<ItemTag key={item.id} item={item}/>))
                                                    }
                                                </div>
                                                <div className={"record_users"}>
                                                    {users.map(user=>(<UserTag  key={user.id} user={user} />))}
                                                </div>
                                            </div>
                                        }
                                        description={
                                            <RecordDate record_date={record.record_date} record_message={record.message} />
                                        }
                                    />
                                    <div>
                                        <Descriptions column={1}>
                                            <Descriptions.Item label="沟通目的" ><pre><span dangerouslySetInnerHTML={{ __html: record.message["purpose"]}}/></pre></Descriptions.Item>
                                            <Descriptions.Item label="沟通准备" ><pre><span dangerouslySetInnerHTML={{ __html: record.message["prepare"]}}/></pre></Descriptions.Item>
                                            <Descriptions.Item label="沟通记录" ><pre><span dangerouslySetInnerHTML={{ __html: record.message["content"]}}/></pre></Descriptions.Item>
                                            {
                                                !_.isEmpty(record.message["next_time"]) ? <Descriptions.Item label="计划下次拜访">{moment(record.message["next_time"]).format('YYYY-MM-DD')} - {moment(record.message["next_time"]).fromNow()}</Descriptions.Item> : ""
                                            }
                                            {
                                                record.message["plan"] ? <Descriptions.Item label="计划和反馈"><pre><span dangerouslySetInnerHTML={{ __html: record.message["plan"]}}/></pre></Descriptions.Item> : ""
                                            }
                                        </Descriptions>
                                    </div>
                                </List.Item>
                            )}}
                        />
                        共{manager_records.length||0}条记录
                    </Card>
                </div>
            </div>
        )
    }
}