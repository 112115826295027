import React from 'react'
import classNames from 'classnames'
import queryString from 'query-string'

import {
    PageHeader,
} from 'antd'

import UserEditForm from '../../../components/EditForm/item'

export default class ItemNew extends React.Component {
    render() {
        const companyId = parseInt(queryString.parse(this.props.location.search)["company"] || null)

        return (
            <div className={classNames("small-content")}>
                <PageHeader
                    title="联系人列表"
                    subTitle="属于我的负责的联系人"
                />
                <div className={classNames("content-info", "small-list")}>
                    <UserEditForm companyId={companyId}/>
                </div>
            </div>
        )
    }
}