import _ from 'lodash'
import React from 'react'
import moment from 'moment'

import styles from './record_date.module.less'

export default class RecordDate extends React.Component { 

    render() {
        const { record_date, record_message } = this.props
        return (
            <div className={styles.record_date}>   
                {
                    record_date ? <span className={"badge"}>
                        {moment(record_date).format('YYYY-MM-DD')}
                    </span>  : ""
                } 
                {
                    record_message && record_message.record_time ? 
                        <span className={"badge"}>{record_message.record_time} - {record_message.record_duration}小时</span>
                        :""
                }
                {
                    record_date ? <span className={"badge"}> {moment(record_date).fromNow()} </span>  : ""
                }
            </div>
        )
    }
}