import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import { inject, observer } from 'mobx-react'

import history from '../../router/history'

import { 
    Input, 
    Button, 
    Form,
    Card,
    DatePicker,
    Select,
    Spin,
    Drawer,
    TimePicker,
    InputNumber,
    Table
} from 'antd';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const { Column } = Table;

@inject('record', 'item', 'user', 'admin')
@observer
class RecordEditForm extends React.Component {

    constructor(props) {
        super(props);
    }
    
    state = {
        recordId: this.props.recordId,
        record: {
            info: {},
            record_time: []
        },
        message: {},
        info: {},
        record_time: [],
        select_items: [],
        items: [],
        fetching: false,
        visible: false
    }

    componentDidMount() {
        this.init()
    }

    init = async () => {
        this.props.user.getUsers()
        this.props.item.getItems()

        if(!(_.isNull(this.props.itemId) || _.isUndefined(this.props.itemId))){
            const item =  await this.props.item.getItem(this.props.itemId)
            this.setState({
                select_items: [{key: parseInt(item.id), label: item.info.name}]
            })
        }

        if(!_.isUndefined(this.props.recordId)){
            const data =  await this.props.record.getRecord(this.props.recordId)
            await this.setState({
                record: data,
                info: data.info||{},
                message: data.message||{},
                select_items: data.items.slice().map(item=>({key: item.id, label: item.info["name"]})),
            })
        } 
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    onSelectChange = (selectedRowKeys, selectedRows) => {
        const selected_items = selectedRows.slice().map(item=>({key: item.id, label: item.info["name"]}))
        this.setState({ select_items: _.uniq([...selected_items]) });
        this.props.form.setFieldsValue({
            items: selected_items,
        });
    }

    fetchUser = async (value) => {
        if(!_.isEmpty(value)){
            this.setState({ items_data: [], fetching: true });
            const data = await this.props.item.searchItem(value)
            this.setState({
                items: data,
                fetching: false
            })
        }
    }

    handleChange = (value) => {
        this.setState({
            items: [],
            select_items: value,
            fetching: false,
        });
    }

    handleFilter = (input, option) => {
        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    handleChnage = (value) => {
        const data = moment().add(value, 'd');
        this.props.form.setFieldsValue({
            next_time: data
        });
    }

    handleSubmit = (event) => {
        event.preventDefault()
        this.props.form.validateFields( async (error, fieldsValue) => {
            if (!error) {
                const record_time = fieldsValue["message"]['record_time'];
                const record_duration = fieldsValue["message"]['record_duration'];
                const next_time = fieldsValue["message"]['next_time']

                const values = {
                    ...fieldsValue,
                    'record_date': fieldsValue['record_date'].format('YYYY-MM-DD'),
                    item_id: this.state.itemId,
                }

                if(!_.isEmpty(record_duration)) {
                    values["message"]['record_time'] = record_time.format('HH:mm')
                    values["message"]['record_duration'] = record_duration
                } else {
                    values["message"]['record_time'] = record_time.format('HH:mm')
                }

                if(next_time) {
                    values["message"]['next_time'] = next_time.format('YYYY-MM-DD')
                }

                values["user_ids"] = values["users"].map(user=>user["key"])
                values["item_ids"] = values["items"].map(item=>item["key"])

                if(!_.isUndefined(this.state.recordId)) {
                    const data = await this.props.record.updateRecord(this.state.recordId, {record: values})
                } else {
                    const data = await this.props.record.createRecord({record: values})
                }
                history.push(`/dashboard/records`)
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const { select_items, record, items, fetching, visible, message } = this.state
        const { users } = this.props.user
        const table_items = this.props.item.items

        const rowSelection = {
            selectedRowKeys: select_items.map(select_item=>select_item.key),
            onChange: this.onSelectChange,
        };

        return (
            <div>
                <Card style={{width: '900px'}}>
                    <Form onSubmit={this.handleSubmit} autoComplete="off">
                        <FormItem
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 15 }}
                            label="客户"
                            style={{ marginBottom: 10 }}
                        >
                            {getFieldDecorator('items', {
                                rules: [{
                                    required: true,
                                    message: '请输入客户',
                                }],
                                initialValue: select_items || []
                            })(
                                <Select
                                    labelInValue
                                    mode="multiple"
                                    placeholder="Select users"
                                    onSearch={this.fetchUser}
                                    filterOption={false}
                                    notFoundContent={fetching ? <Spin size="small" /> : null}
                                    onChange={this.handleChange}
                                    style={{ width: '100%' }}
                                    size={"large"}
                                    optionLabelProp="label"
                                >
                                    {
                                        items.map(item=>
                                            <Option key={item.id} value={item.id} label={item.info["name"]}>
                                                <div className="item-select">
                                                    <span className="label">{item.info["name"]}</span>
                                                    <span className="caption">{item.company.info["name"]}</span>
                                                </div>
                                            </Option>
                                        )
                                    }
                                </Select>
                            )}
                            <Button onClick={this.showDrawer}>添加联系人</Button>
                        </FormItem>
                        <FormItem 
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 15 }}
                            label="负责人"
                            style={{ marginBottom: 10 }}
                        >   
                            {getFieldDecorator('users', {
                                rules: [{
                                    required: true,
                                    message: '请输入负责人',
                                }],
                                initialValue: message.users||[{key:this.props.admin.user.id,label:this.props.admin.user.nickname}]
                            })(
                                <Select
                                    mode="multiple"
                                    labelInValue
                                    placeholder="Select users"
                                    filterOption={(input, option) => this.handleFilter(input, option)}
                                    style={{ width: '100%' }}
                                    size={"large"}
                                >
                                    {
                                        users.map(user=>(<Option key={user.id} value={user.id}>{user.nickname}</Option>))
                                    }
                                </Select>
                            )}
                        </FormItem>
                        <FormItem
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 10 }}
                            label="日期"
                            style={{ marginBottom: 10 }}
                        >
                            {getFieldDecorator('record_date', {
                                rules: [{
                                    required: true,
                                    message: '请输入日期',
                                }],
                                initialValue: moment(record.record_date)||null
                            })(
                                <DatePicker />
                            )}
                        </FormItem>
                        <FormItem
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 8 }}
                            label="时间"
                            style={{ marginBottom: 10 }}
                        >
                            <Form.Item
                                style={{ marginBottom: 0 }}
                            >
                                <Form.Item
                                    help="开始时间"
                                    style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                                >
                                    {getFieldDecorator('message[record_time]', {
                                        initialValue: message.record_time ? moment(message.record_time, 'HH:mm') : moment().set({'hour':10, 'minute':0})
                                    })(
                                        <TimePicker format={'HH:mm'} />
                                    )}
                                </Form.Item>
                                <span style={{ display: 'inline-block', width: '24px', textAlign: 'center' }}>
                                    -
                                </span>
                                <Form.Item
                                    help="小时"
                                    style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                                >
                                    {getFieldDecorator('message[record_duration]', {
                                        initialValue: message.record_duration || 0
                                    })(
                                        <InputNumber /> 
                                    )}
                                    
                                </Form.Item>
                            </Form.Item>
                        </FormItem>
                        <FormItem 
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 20 }}
                            label="沟通目的"
                            style={{ marginBottom: 10 }}
                        >
                            {getFieldDecorator('message[purpose]', {
                                rules: [{
                                    required: true,
                                    message: '请输入目的',
                                }],
                                initialValue: message.purpose||""
                            })(
                               <TextArea placeholder="沟通目的" autosize={{ minRows: 3}} />
                            )}
                        </FormItem>
                        <FormItem 
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 20 }}
                            label="沟通准备"
                            style={{ marginBottom: 10 }}
                        > 
                            {getFieldDecorator('message[prepare]', {
                                rules: [{
                                    required: true,
                                    message: '请输入沟通准备',
                                }],
                                initialValue: message.prepare||""
                            })(
                                <TextArea placeholder="沟通准备" autosize={{ minRows: 3}} />
                            )}
                        </FormItem>
                        <FormItem 
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 20 }}
                            label="沟通内容"
                            style={{ marginBottom: 10 }}
                        > 
                                {getFieldDecorator('message[content]', {
                                    rules: [{
                                        required: true,
                                        message: '请输入沟通记录',
                                    }],
                                    initialValue: message.content||""
                                })(
                                    <TextArea placeholder="沟通记录" autosize={{ minRows: 6}} />
                                )}
                        </FormItem>
                        <FormItem
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 10 }}
                            label="计划下次"
                            style={{ marginBottom: 10 }}
                        >
                            {getFieldDecorator('message[next_time]', {
                                initialValue: message.next_time ? moment(message.next_time) : null
                            })(
                                <DatePicker />
                            )}

                            <span style={{marginLeft:20}}><InputNumber onChange={this.handleChnage}/>   天之后</span>
                        </FormItem>
                        <FormItem 
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 20 }}
                            label="反馈和计划"
                            style={{ marginBottom: 10 }}
                        > 
                                {getFieldDecorator('message[plan]', {
                                    initialValue: message.plan||""
                                })(
                                    <TextArea placeholder="反馈和计划" autosize={{ minRows: 2}} />
                                )}
                        </FormItem>                
                        <FormItem
                            wrapperCol={{ span: 12, offset: 3 }}
                        >
                            <Button type="primary" htmlType="submit">保存</Button>
                        </FormItem>
                    </Form>
                </Card>
                <Drawer
                    title="选择联系人"
                    width={720}
                    placement={"left"}
                    onClose={this.onClose}
                    visible={visible}
                    style={{
                        overflow: 'auto',
                        height: 'calc(100% - 100px)',
                        paddingBottom: '100px',
                    }}
                    >

                    <Table 
                        dataSource={table_items} 
                        rowKey={record=>record.id}
                        style={{marginTop:20}}
                        rowSelection={rowSelection}
                        size="small"
                    >
                        <Column
                            title="公司"
                            key="company"
                            width={150}
                            // sortDirections={['descend', 'ascend']}
                            // sorter={(a, b) =>( a.company.info.name.localeCompare(b.company.info.name)}
                            render={(data, record) => {
                                return (
                                    <span>{record.company.info ? record.company.info.name : ""}</span>
                                )
                            }}
                        />
                        <Column
                            title="姓名"
                            key="name"
                            width={150}
                            sortDirections={['descend', 'ascend']}
                            sorter={(a, b) => a.info.name.localeCompare(b.info.name)}
                            render={(data, record) => {
                                return (
                                    <span>{record.info["name"]}</span>
                                )
                            }}
                        />
                        <Column
                            title="职位"
                            key="job"
                            width={100}
                            sortDirections={['descend', 'ascend']}
                            sorter={(a, b) => a.info.job.localeCompare(b.info.job)}
                            render={(data, record) => {
                                return (
                                    <span>{record.info["job"]}</span>
                                )
                            }}
                        />
                    </Table>
                </Drawer>
            </div>
        )
    }
}

export default Form.create()(RecordEditForm);