const prod = process.env.NODE_ENV === 'production'
const protocol = document.location.protocol == 'https:'


const http_api = {
  'SERVER_URL': prod ? 'http://tempura_api.ramen.idailymedia.com' :'http://127.0.0.1:4041',
  'CLIENT_URL': prod ? 'http://tempura.ramen.idailymedia.com' :'http://127.0.0.1:3000',
}

const https_api = {
  'SERVER_URL': prod ? 'https://crm_api.nerv.idailymedia.com' :'http://127.0.0.1:4041',
  'CLIENT_URL': prod ? 'https://crm.nerv.idailymedia.com' :'http://127.0.0.1:4041',
}

module.exports = protocol ? https_api : http_api