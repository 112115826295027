import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'

import {
    Card,
    Table,
    Button,
    Tag,
    Dropdown,
    Menu,
    PageHeader,
    Icon
} from 'antd'
import history from '../../router/history'

const { Column } = Table;

@inject('item', 'user')
@observer
export default class AuthList extends React.Component {

    state = {
        selectedRowKeys: [],
        users: [],
        user: {},
    }

    componentDidMount() {
        this.init()
    }

    init = () => {
        this.props.item.getAuthItems()
        this.props.user.getUsers()
    }

    handleRecordClick = (e,key) => {
        e.preventDefault()
        history.push("/dashboard/records_new?item="+key)
    }

    handleChange = (value) => {
        this.setState({
            user: value
        })
    }

    handleDeleteClick = (e,key) => {
        e.preventDefault()
        this.props.item.destroyManagerItem(key)
    }

    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({
                selectedRowKeys: selectedRowKeys
            })
        },
    };

    handleClick = ({key}, id) => {
        if(!_.isNull(key)) {
            if (id == "add") {
                const params = {
                    item: {
                        user: key,
                        items: this.state.selectedRowKeys
                    }
                }
                this.props.item.addAuth(params)
            } else if (id == "remove") {
                const params = {
                    item: {
                        user: key,
                        items: this.state.selectedRowKeys
                    }
                }
                this.props.item.removeAuth(params)
            } else if (id == "move") {
                const params = {
                    item: {
                        user: key,
                        items: this.state.selectedRowKeys
                    }
                }
                this.props.item.moveAuth(params)
            } else {
                const params = {
                    item: {
                        user: key,
                        items: [id]
                    }
                }
                this.props.item.addAuth(params)
            }
        }
    }

    handleClose = (user_id, record_id) => {
        const params = {
            item: {
                user: user_id,
                items: [record_id]
            }
        }
        this.props.item.removeAuth(params)

    }

    render() {
        const auth_items = this.props.item.auth_items
        const users = this.props.user.users || []

        const menu = (id) => (
            <Menu onClick={(e) => this.handleClick(e, id)}>
                {
                    users.map(user=>(
                        <Menu.Item key={user.id} value={user.id}>{user.nickname}</Menu.Item>
                    ))
                }
            </Menu>
        )

        return (
            <div className={classNames("big-content")}>
                <PageHeader
                    title="权限管理"
                    subTitle="按照联系人展示"
                />
                <div className={classNames("content-info", "big-list")}>
                    <Card bordered={false}>
                        <Dropdown overlay={menu("add")}>
                            <Button type="primary" style={{marginLeft: 10}} onClick={(e)=>this.handleClick(e, "add")}>添加</Button>
                        </Dropdown>
                        <Dropdown overlay={menu("remove")}>
                            <Button type="danger" style={{marginLeft: 10}} onClick={(e)=>this.handleClick(e, "remove")}>移除</Button>
                        </Dropdown>
                        <Dropdown overlay={menu("move")}>
                            <Button type="danger" style={{marginLeft: 10}} onClick={(e)=>this.handleClick(e, "move")}>转移</Button>
                        </Dropdown>
                    </Card>

                    <Card bordered={false} style={{marginTop: 20}}>
                        <Table 
                            dataSource={auth_items}
                            rowSelection={this.rowSelection}
                            rowKey={record=>record.id}
                            pagination={{ pageSize: 600 }}
                        >
                            <Column
                                title="公司"
                                key="company"
                                width={250}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) =>( (a.company.info ? a.company.info.name : "").localeCompare(b.company.info ? b.company.info.name : ""))}
                                render={(data, record) => {
                                    const company_info = record.company ? record.company.info : {}
                                    return (
                                        <span>
                                            <div>{company_info.name || ""}</div>
                                        </span>
                                    )
                                }}
                            />
                            <Column
                                title="职位"
                                key="job"
                                width={250}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => a.info.job.localeCompare(b.info.job)}
                                render={(data, record) => {
                                    return (
                                        <span>{record.info.job}</span>
                                    )
                                }}
                            />
                            <Column
                                title="姓名"
                                key="name"
                                width={200}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => a.info.name.localeCompare(b.info.name)}
                                render={(data, record) => {
                                    return (
                                        <span>
                                            {record.info.name}
                                        </span>
                                    )
                                }}
                            />

                            <Column
                                title="重要度"
                                key="score"
                                width={120}
                                filters={
                                    [{
                                        text: '1星',
                                        value: 1,
                                    },{
                                        text: "2星",
                                        value: 2,
                                    },{
                                        text: "3星",
                                        value: 3,
                                    },{
                                        text: "4星",
                                        value: 4,
                                    },{
                                        text: "5星",
                                        value: 5,
                                    }]
                                }
                                onFilter={(value, record) => record.info.score == value}
                                filterMultiple={false}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => a.info.score - b.info.score}
                                render={(data, record) => {
                                    return (
                                        <span>{record.info.score||""}</span>
                                    )
                                }}
                            />
                            <Column
                                title="创建时间"
                                key="created_at"
                                width={150}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => a.created_at - b.created_at}
                                render={(data, record) => {
                                    return (
                                        <span>{moment(record.created_at).format("YYYY-MM-DD")}</span>
                                    )
                                }}
                            />
                            <Column
                                title="更新时间"
                                key="updated_at"
                                width={150}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => (a.info.updated_at||0) - (b.info.updated_at||0)}
                                render={(data, record) => {
                                    return (
                                        <span>{moment(record.updated_at).format("YYYY-MM-DD")}</span>
                                    )
                                }}
                            />
                            <Column
                                title="负责人"
                                dataIndex="auth_user_ids"
                                key="auth_user_ids"
                                width={200}
                                render={(data, record) => {
                                    const auth_users = record.auth_users || []
                                        return (
                                            <span>
                                                <span>{auth_users.map(user=><Tag key={user.id} closable afterClose={() => this.handleClose(user.id, record.id)}>{user.nickname}</Tag>)}</span>
                                                <span>
                                                    <Dropdown overlay={menu(record.id)}>
                                                        <Tag
                                                            style={{ background: '#fff', borderStyle: 'dashed' }}
                                                        >
                                                            <Icon type="plus" /> New
                                                        </Tag>
                                                    </Dropdown>
                                                </span>
                                            </span>
                                        )

                                    }
                                }
                            />
                        </Table>
                        共{auth_items.length}位客户
                    </Card>
                </div>
            </div>
        )
    }
}