import _ from 'lodash'
import { observable, action, runInAction } from 'mobx'

import ApiService from '../services/ApiService'
import BoardService from '../services/BoardService'
import AdminService from '../services/AdminService'
import StorageService from '../services/StorageService'

class Company {
    @observable companies = []

    getCompany = async (key) => {
        const data = await BoardService.getCompany(StorageService.getToken(), key)
        return data
    }

    @action
    getCompanies = async (params={}) => {
        const data = await BoardService.getCompanies(StorageService.getToken(), params)
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.companies = data
            })
        }
    }

    @action
    getCategoryCompanies = async (id) => {
        const data = await ApiService.getCategoryCompanies(StorageService.getToken(), id)
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.companies = data
            })
        }
    }

    @action
    createCompany = async (params) => {
        const data = await BoardService.createCompany(StorageService.getToken(), params)
        if(!_.isNull(data)) {
            this.companies.push(data)
        }
    }

    @action
    updateCompany = async (key, params) => {
        const data = await BoardService.updateCompany(StorageService.getToken(), key, params)
        if(!_.isNull(data)) {
            const index = _.findIndex(this.companies, {id: parseInt(key)})
            if(index > -1) {
                runInAction(()=>{
                    this.companies[index] = data
                })
            }
        }
    }

    @action
    destroyCompany = async (key) => {
        const data = await BoardService.destroyCompany(StorageService.getToken(), key)
        if(!_.isNull(data)) {
            const index = _.findIndex(this.companies, {id: parseInt(key)})
            if(index > -1) {
                this.companies.splice(index,1)
            }
        }
    }

    getCompanyLogs = async (company_id) => {
        const data = await AdminService.getCompanyLogs(StorageService.getToken(), company_id)
        return data
    }
}

export default new Company()