import _ from 'lodash'
import { observable, action, runInAction } from 'mobx'

import ApiService from '../services/ApiService'
import BoardService from '../services/BoardService'
import AdminService from '../services/AdminService'
import StorageService from '../services/StorageService'


class Record {
    @observable records = []
    @observable manager_records = []

    getRecord = async (key) => {
        const data = await BoardService.getRecord(StorageService.getToken(), key)
        return data
    }

    getItemRecords = async (item_id) => {
        const data = await BoardService.getItemRecords(StorageService.getToken(), item_id)
        return data
    }




    @action
    getRecords = async () => {
        const data = await BoardService.getRecords(StorageService.getToken())
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.records = data
            })
        }
    }

    @action 
    createRecord = async (params) => {
        const data = await BoardService.createRecord(StorageService.getToken(), params)
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.records.push(data)
            })
        }
        return data
    }

    @action 
    updateRecord = async (key, params) => {
        const data = await BoardService.updateRecord(StorageService.getToken(), key, params)
        if(!_.isNull(data)) {
            const index = _.findIndex(this.records, {id: data.id})
            if(index > -1) {
                runInAction(()=>{
                    this.records[index]=data
                })
            }
        }
        return data
    }

    @action 
    destroyRecord = async (key) => {
        await ApiService.destroyRecord(StorageService.getToken(), key)
        const index = _.findIndex(this.records, {id: parseInt(key)})
        if(index > -1) {
            runInAction(()=>{
                this.manager_records.splice(index,1)
            })
        }
    }


    @action
    getManagerRecords = async (params) => {
        let data = []
        if(params["category"]) {
            data = await AdminService.getCategoryManagerRecords(StorageService.getToken(), params["category"], params)
        } else {
            data = await AdminService.getManagerRecords(StorageService.getToken(), params)
        }

        if(!_.isNull(data)) {
            runInAction(()=>{
                this.manager_records = data
            })
        }
    }

    getManagerItemRecords = async (item_id) => {
        const data = await AdminService.getManagerItemRecords(StorageService.getToken(), item_id)
        return data
    }

}

export default new Record()