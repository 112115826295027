import _ from 'lodash'
import React from 'react';
// import moment from 'moment'

import { 
    Card,
    Divider,
    Button,
    Rate,
    Tag
} from 'antd';

import history from '../../router/history'
import LinkDescription from './LinkDescription'

import { DescriptionList } from 'ant-design-pro';
const { Description } = DescriptionList;

class ItemCard extends React.Component {

    handleEdit = () => {
        if(!_.isEmpty(this.props.item)) {
            history.push("/dashboard/items/"+this.props.item.id+"/edit")
        }
    }

    render() {
        const item = this.props.item||{}
        const message = this.props.item.message||{}
        const info = this.props.item.info||{}

        const company_info = this.props.item.company ? this.props.item.company.info : {}

        const private_email = message.private_email|| []
        const office_email = message.office_email|| []
        const private_phone = message.private_phone|| []
        const office_phone = message.office_phone|| []
        const delivery_address = message.delivery_address|| []

        return (
            <div style={{width: 500}}>
                <Card>
                    <DescriptionList size="large" title="基础信息" col={1}>
                        <Description term="公司">{company_info ? company_info.name : ""}</Description>
                        <Description term="部门">{info.job||""}</Description>
                        <Description term="细分部门">{info.subdivision||""}</Description>
                        <Description term="姓名">{info["name"]||""}</Description>
                        <Description term="性别">{info.sex == "men" ? "男" : ""}{info.sex == "women" ? "女" : ""}</Description>
                        <Description term="重要度"><Rate value={parseInt(info.score)} count={5} disabled={false}/></Description>
                    </DescriptionList>
                    <Divider />
                    <DescriptionList size="large" title="联系方式" col={1}>
                        {
                            private_email.map((item,index)=><Description key={item} term={"私人邮箱"+(private_email.length==1?"":index+1)}>{item||""}</Description>)
                        }
                        {
                            office_email.map((item,index)=><Description key={item} term={"公司邮箱"+(office_email.length==1?"":index+1)}>{item||""}</Description>)
                        }
                        {
                            private_phone.map((item, index)=><Description key={item} term={"私人电话"+(private_phone.length==1?"":index+1)}>{item||""}</Description>)
                        }
                        {
                            office_phone.map((item,index)=><Description key={item} term={"公司电话"+(office_phone.length==1?"":index+1)}>{item||""}</Description>)
                        }
                        {
                            delivery_address.map((item,index)=><Description key={item} term={"收件地址"+(delivery_address.length==1?"":index+1)}>{item||""}</Description>)
                        }
                        <Description term="qq">{info.qq||""}</Description>
                        <Description term="微信">{info.wechat||""}</Description>
                    </DescriptionList>
                    <Divider />
                    <DescriptionList size="large" title="其他信息" col={1}>
                        <Description term="生日">{info.birthday||""}/{info.birthmonth||""}</Description>
                        <Description term="描述">{info.describe||""}</Description>
                        <Description term="标签">{(message.tags||[]).map(tag=>(<Tag key={tag}>{tag}</Tag>))}</Description>
                        <Description term="备注"><LinkDescription description={info["remark"]} /></Description>
                    </DescriptionList>
                    <Divider />
                    <DescriptionList size="large" col={2}>
                        <Description>
                            <Button onClick={this.handleEdit}>编辑</Button>
                        </Description>
                    </DescriptionList>
                </Card>
            </div>
        )
    }
}

export default ItemCard;