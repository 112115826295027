import React from 'react'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'

import { PageHeader } from 'antd'

import CompanyCard from '../../../components/ShowCard/CompanyCard'
import CompanyItems from '../../../components/ListTable/company_items'

@inject('item', 'company')
@observer
export default class CompanyShow extends React.Component {

    state = {
        data: {},
        items: []
    }

    componentDidMount() {
        this.init()
    }

    init = async () => {
        const companyId = this.props.match.params.id
        const data = await this.props.company.getCompany(companyId)
        const items = await this.props.item.getCompanyItems(companyId)
        this.setState({
            data: data,
            items: items || []
        })
    }

    render() {
        const { data, items } = this.state
        return (
            <div className={classNames("big-content")}>
                <PageHeader
                    title="公司信息"
                    subTitle="公司详细信息和相关联系人"
                />
                <div className={classNames("content-info", "big-list", "show-info")}>
                    <div>
                        <CompanyCard data={data}/>
                    </div>

                    <div style={{marginLeft:10}}>
                        <CompanyItems companyId={this.props.match.params.id} items={items} />
                    </div>
                </div>
            </div>
        )
    }
}