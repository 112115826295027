import _ from 'lodash'
import React from 'react';
import { Tag } from 'antd';
import config from '../../config/index'
import { inject, observer } from 'mobx-react'

@inject('admin')
@observer
export default class UserTag extends React.Component { 

    render() {
        const {user} = this.props
        return (
            user ? 
                <span>
                    <a href={this.props.admin.isAuth("inspect")  ? config.CLIENT_URL + '/dashboard/manager_records/?user=' + user.id : ""}>
                        <Tag>{user.nickname}</Tag>
                    </a>
                </span> : ""
        )
    }
}