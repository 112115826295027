import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'

import { 
    Card, 
    Avatar,
    PageHeader,
    Descriptions,
    Comment,
    Form,
    Input,
    Button,
    message,
    List
} from 'antd';

const { TextArea } = Input;

import UserTag from '../../../components/LinkTag/user'
import ItemTag from '../../../components/LinkTag/item'

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
    itemLayout="horizontal"
    renderItem={comment => <Comment
          actions={[]}
          author={comment.user.nickname}
          avatar={comment.user.avatar}
          content={comment.comment}
          datetime={moment(comment.created_at).format('YYYY-MM-DD HH:mm:ss')}
        />}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <div>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        Add Comment
      </Button>
    </Form.Item>
  </div>
);


@inject('admin', 'record', 'comment')
@observer
export default class RecordShow extends React.Component {

    state = {
        record: {
            items: [],
            users: []
        },
        message: {},
        comments: [],
        submitting: false,
        value: ""
    }

    componentDidMount() {
        this.init()
    }

    init = async () => {
        const recordId = this.props.match.params.id
        const data = await this.props.record.getRecord(recordId)
        const comments = await this.props.comment.getManagerComments(recordId)
        
        this.setState({
            record: data,
            message: data.message || {},
            comments: comments
        })
    }

    handleChange = e => {
        this.setState({
            value: e.target.value,
        });
    };

    handleSubmit = async () => {
        const value = this.state.value

        if (_.isEmpty(value)) {
            message.error("不能回复为空", 3)
        } else {
            const recordId = this.props.match.params.id
            const proload = {
                "record_id": recordId,
                "comment": value
            }
            const data = await this.props.comment.createManagerComment(proload)
            console.log(data)
            this.setState({
                comments: [data, ...this.state.comments]
            })
        }
    }

    render() {
        const { record, message, comments, submitting, value } = this.state

        return (
            <div className={classNames("small-content")}>
                <PageHeader
                    title="记录详情"
                    subTitle=""
                />
                <div className={classNames("content-info", "small-list")}>
                    <Card className={"record_show"}>
                        <Descriptions size="large" column={1}>
                            <Descriptions.Item label="客户" className={"record_item"}>
                                {record.items.map(item=>(<ItemTag key={item.id} item={item}/>))}
                            </Descriptions.Item>
                            <Descriptions.Item label="负责人">
                                {record.users.map(user=>(<UserTag  key={user.id} user={user} />))}
                            </Descriptions.Item>
                            <Descriptions.Item label="日期"><span className={"badge"}>{record.record_date||""} - {record.record_date ? moment(record.record_date).fromNow() : ""}</span></Descriptions.Item>
                            <Descriptions.Item label="时间">{message.record_time ? <span className={"badge"}><span>{message.record_time}</span> - <span>{message.record_duration}小时</span></span>:""}</Descriptions.Item>
                            <Descriptions.Item label="沟通目的"><pre><span dangerouslySetInnerHTML={{ __html: message.purpose}}/></pre></Descriptions.Item>
                            <Descriptions.Item label="沟通准备"><pre><span dangerouslySetInnerHTML={{ __html: message.prepare}}/></pre></Descriptions.Item>
                            <Descriptions.Item label="沟通记录"><pre><span dangerouslySetInnerHTML={{ __html: message.content}}/></pre></Descriptions.Item>
                            <Descriptions.Item label="计划下次"><span className={"badge"}>{message.next_time ? message.next_time + "-" + moment(message["next_time"]).fromNow() : ""}</span></Descriptions.Item>
                            <Descriptions.Item label="计划和反馈"><pre><span dangerouslySetInnerHTML={{ __html: message.plan}}/></pre></Descriptions.Item>
                        </Descriptions>
                    </Card>
                </div>

                <div className={classNames("content-info", "small-list")}>
                    <Card className={"record_show"}>
                        {comments.length > 0 && <CommentList comments={comments} />}
                    </Card>
                </div>

                <div className={classNames("content-info", "small-list")}>
                    <Card className={"record_show"}>
                        <Comment
                            avatar={
                                <Avatar
                                src={this.props.admin.user.avatar}
                                alt={this.props.admin.user.nickname}
                                />
                            }
                            content={
                                <Editor
                                    onChange={this.handleChange}
                                    onSubmit={this.handleSubmit}
                                    submitting={submitting}
                                    value={value}
                                />
                            }
                        />
                    </Card>
                </div>
            </div>
        )
    }
}