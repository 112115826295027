import React from 'react'
import classNames from 'classnames'
import {
    PageHeader
} from 'antd'

import CompanyEditForm from '../../../components/EditForm/company'

export default class CompanyEdit extends React.Component {
    render() {
        return (
            <div className={classNames("small-content")}>
                <PageHeader
                    title="编辑公司"
                    subTitle="编辑公司信息"
                />
                <div className={classNames("content-info", "small-list")}>
                    <CompanyEditForm companyId={this.props.match.params.id}/>
                </div>
            </div>
        )
    }
}