import _ from 'lodash'
import { observable, action, runInAction } from 'mobx'

import ApiService from '../services/ApiService'

// import AuthService from '../services/AuthService'
import BoardService from '../services/BoardService'
import AdminService from '../services/AdminService'
import StorageService from '../services/StorageService'


class Manager {
    @observable categories = []
    @observable tags = []

    async getTag(key) {
        const data = await AdminService.getTag(StorageService.getToken(), key)
        return data
    }

    // tags
    @action
    async getTags() {
        const data = await BoardService.getTags(StorageService.getToken())
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.tags = data
            })
        }
    }

    @action
    async createTag(params) {
        const data = await AdminService.createTag(StorageService.getToken(), params)
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.tags.push(data)
            })
        }
    }

    @action
    async updateTag(key, params) {
        const data = await AdminService.updateTag(StorageService.getToken(), key, params)
        if(!_.isNull(data)) {
            const index = _.findIndex(this.tags, {id: data.id})
            if(index > -1) {
                runInAction(()=>{
                    this.tags.splice(index, 1, data)
                })
            }
        }
    }

    @action
    async destroyTags(key) {
        await AdminService.destroyCategory(StorageService.getToken(), key)
        const index = _.findIndex(this.tags, {id: parseInt(key)})
        if(index > -1) {
            runInAction(()=>{
                this.tags.splice(index,1)
            })
        }
    }
    
    // getCategory
    async getCategory (key) {
        const data = await AdminService.getCategory(StorageService.getToken(), key)
        return data
    }


    @action
    async getCategories() {
        const data = await BoardService.getCategories(StorageService.getToken())
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.categories = data
            })
        }
    }

    async createCategory(params) {
        const data = await AdminService.createCategory(StorageService.getToken(), params)
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.categories.push(data)
            })
        }
    }

    async updateCategory(key, params) {
        const data = await AdminService.updateCategory(StorageService.getToken(), key, params)
        if(!_.isNull(data)) {
            const index = _.findIndex(this.categories, {id: data.id})
            if(index > -1) {
                runInAction(()=>{
                    this.categories.splice(index, 1, data)
                })
            }
        }
    }

    async destroyCategory(key) {
        await AdminService.destroyCategory(StorageService.getToken(), key)
        const index = _.findIndex(this.categories, {id: parseInt(key)})
        if(index > -1) {
            runInAction(()=>{
                this.categories.splice(index,1)
            })
        }
    }
}

export default new Manager()