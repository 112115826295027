import _ from 'lodash'
import React from 'react';
import styles from './item.module.less'
import config from '../../config/index'
import { inject, observer } from 'mobx-react'

@inject('admin')
@observer
export default class ItemTag extends React.Component { 
    render() {
        const {item} = this.props
        const index = item.auth_user_ids.indexOf(parseInt(this.props.admin.user.id))
        const flag = index > -1 || this.props.admin.isAuth("inspect") 
        return (
            item ? 
            <span>
                <a className={styles.tag} href={flag ? config.CLIENT_URL + '/dashboard/items/' + item.id:""}>
                    <span className={styles.name}>{item.info.name}</span>
                    <span className={styles.info}>{item.company ? item.company.info["name"] : ""} { item.info.job ? " - " + item.info.job : ""}</span>
                </a>
            </span>
            : ""
        )
    }
}