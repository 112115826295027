import React from 'react'
import classNames from 'classnames'
import RecordEditForm from '../../../components/EditForm/record'
import {
    PageHeader,
} from 'antd'

export default class RecordEdit extends React.Component {
    render() {
        return (
            <div className={classNames("small-content")}>
                <PageHeader
                    // onBack={() => null}
                    title="编辑记录"
                    subTitle="编辑我的记录"
                />
                <div className={classNames("content-info", "small-list")}>
                        <RecordEditForm recordId={this.props.match.params.id}/>
                </div>
            </div>
        )
    }
}