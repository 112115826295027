import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'

import {
    Card,
    PageHeader,
    Table,
    Tag,
    Divider,
    Popconfirm
} from 'antd'

import history from '../../../router/history'
import config from '../../../config'

const { Column } = Table;
const CheckableTag = Tag.CheckableTag;

@inject('item', 'manager')
@observer
export default class Items extends React.Component {

    state = {
        selectedTag: null,
    };

    componentDidMount() {
        this.props.item.getManagerItems()
        this.props.manager.getCategories()
    }

    handleEditClick = (e,key) => {
        e.preventDefault()
        history.push("/dashboard/items/"+key+"/edit")
    }

    handleHistroyClick = (e,key) => {
        e.preventDefault()
        history.push("/dashboard/manager/items/"+key+"/logs")
    }

    handleRecoverRecordClick = (e, key) => {
        e.preventDefault()
        this.props.item.recoverItem(key)
    }

    handleDeleteClick = (e,key) => {
        e.preventDefault()
        this.props.item.destroyItem(key)
    }

    handleChange(tag, checked) {
        if(checked){
            this.props.item.getManagerCategoryItems(tag)
            this.setState({ selectedTag: tag });
        } else {
            this.props.item.getManagerItems()
            this.setState({ selectedTag: null });
        }
    }

    render() {
        const { manager_items } = this.props.item
        const { selectedTag } = this.state;
        const { categories } = this.props.manager

        return (
            <div className={classNames("big-content")}>
                <PageHeader
                    title="管理联系人"
                    subTitle="所有联系人"
                />
                <div className={classNames("content-info", "big-list")}>
                    <Card bordered={false} style={{marginTop:"10px"}} >
                        <div className="category">
                            <h4>分类:</h4>
                            <div>
                                {categories.map(tag => (
                                    <CheckableTag
                                        key={tag.id}
                                        checked={selectedTag == tag.id ? !!selectedTag : false}
                                        onChange={checked => this.handleChange(tag.id, checked)}
                                    >
                                        {tag.name}
                                    </CheckableTag>
                                ))}
                            </div>
                        </div>
                    </Card>
                    <Card bordered={false} style={{marginTop:"10px"}}>
                        <Table
                            dataSource={manager_items}
                            rowKey={record=>record.id}
                            pagination={{ pageSize: 600 }}
                        >   
                            <Column
                                title="公司"
                                key="company"
                                width={250}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) =>( (a.company.info ? a.company.info.name : "").localeCompare(b.company.info ? b.company.info.name : ""))}
                                render={(data, record) => {
                                    const company_info = record.company ? record.company.info : {}
                                    return (
                                        <span>
                                            <a href={record.company ? config.CLIENT_URL + '/dashboard/manager/companies/' + record.company.id : ""}>
                                                {company_info.name||""}
                                            </a>
                                        </span>
                                    )
                                }}
                            />
                            <Column
                                title="职位"
                                key="job"
                                width={250}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => a.info.job.localeCompare(b.info.job)}
                                render={(data, record) => {
                                    return (
                                        <span>{record.info.job}</span>
                                    )
                                }}
                            />
                            <Column
                                title="姓名"
                                key="name"
                                width={200}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => a.info.name.localeCompare(b.info.name)}
                                render={(data, record) => {
                                    return (
                                        <span>
                                            <a href={config.CLIENT_URL + '/dashboard/manager/items/' + record.id}>
                                                {record.info.name}
                                            </a>
                                        </span>
                                    )
                                }}
                            />
                            <Column
                                title="负责人"
                                key="auth_users"
                                width={150}
                                // sortDirections={['descend', 'ascend']}
                                // sorter={(a, b) => a.info.name.localeCompare(b.info.name)}
                                render={(data, record) => {
                                    const auth_users = record.auth_users || []
                                    return (
                                        auth_users.map(user=><Tag key={user.id}>{user.nickname}</Tag>)
                                    )
                                }}
                            />
                            <Column
                                title="重要度"
                                key="score"
                                width={120}
                                filters={
                                    [{
                                        text: '1星',
                                        value: 1,
                                    },{
                                        text: "2星",
                                        value: 2,
                                    },{
                                        text: "3星",
                                        value: 3,
                                    },{
                                        text: "4星",
                                        value: 4,
                                    },{
                                        text: "5星",
                                        value: 5,
                                    }]
                                }
                                onFilter={(value, record) => record.info.score == value}
                                filterMultiple={false}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => a.info.score - b.info.score}
                                render={(data, record) => {
                                    return (
                                        <span>{record.info.score||""}</span>
                                    )
                                }}
                            />
                            <Column
                                title="距离上次访问"
                                key="latest_record"
                                width={150}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => (a.info.latest_record||0) - (b.info.latest_record||0)}
                                render={(data, record) => {
                                    return (
                                        <span>{record.latest_record ? moment(record.latest_record).fromNow() : "最近没有访问"}</span>
                                    )
                                }}
                            />
                            <Column
                                title="创建时间"
                                key="created_at"
                                width={150}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => a.created_at - b.created_at}
                                render={(data, record) => {
                                    return (
                                        <span>{moment(record.created_at).format("YYYY-MM-DD")}</span>
                                    )
                                }}
                            />
                            <Column
                                title="Action"
                                key="action"
                                render={(data, record) => (
                                    <span>
                                        {
                                            record.exist ? "" : 
                                            <span>
                                                <Popconfirm title="是否恢复该联系人" okText="Yes" cancelText="No" onConfirm={(e)=>this.handleRecoverRecordClick(e, data.id)}>
                                                    <a href='javascript:;'>恢复</a>
                                                </Popconfirm>
                                                <Divider type="vertical" />
                                            </span>
                                        }
                                        <a href='javascript:;' onClick={(e)=>this.handleEditClick(e, data.id)}>编辑</a>
                                        <Divider type="vertical" />
                                        <a href='javascript:;' onClick={(e)=>this.handleHistroyClick(e, data.id)}>历史</a>
                                        <Divider type="vertical" />
                                        <Popconfirm title="是否删除该联系人" okText="Yes" cancelText="No" onConfirm={(e)=>this.handleDeleteClick(e, data.id)}>
                                            <a href='javascript:;'>删除</a>
                                        </Popconfirm>
                                    </span>
                                )}
                            />
                        </Table>
                        共{manager_items.length}位客户
                    </Card>
                </div>
            </div>
        )
    }
}