import axios from 'axios'

import api from '../config/api'
import ErrorService from '../services/ErrorService'

class ApiService {

    async apiCall(url, method = 'GET', token = false, params = null, query = null) {
        let payload = {
            url : url,
            method,
            query,
            headers: this.buildHeaders(token),
        }
        if (query) {
            payload.params = query;
        }
        if (params) {
            payload.data = params;
        }
        const res = await axios.request(payload).catch((error)=>{
            return error.response
        })
        return res;
    }

    buildHeaders(token = false) {
        let headers = {};
        headers['Content-type'] = 'application/json'
        if (token) {
            headers['Authorization'] = token
        }
        return headers;
    }

    // 权限----------------------------------------
    async login(params) {
        const url = `${api.auth}/login`
        const res = await this.apiCall(url, 'POST', false, params);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    // user------------------------------------------
    async getUsers(token) {
        const url = `${api.auth}/users`
        const res = await this.apiCall(url, 'GET', token, null);
        const data = ErrorService.handleCommonError(res)
        return data;
    }

    // item-------------------------------------------
    // 有参数
    // async getCategoryItems(token, key, params) {
    //     const url = `${api.category}/${key}/items`
    //     const res = await this.apiCall(url, 'GET', token, null, params);
    //     const data = ErrorService.handleCommonError(res)
    //     return data;
    // }

    // async getCompanyItems(token, key) {
    //     const url = `${api.company}/${key}/items`
    //     const res = await this.apiCall(url, 'GET', token);
    //     const data = ErrorService.handleCommonError(res)
    //     return data;
    // }

    // async exportCsv(token, params) {
    //     const url = `${api.item}/export_csv`
    //     const res = await this.apiCall(url, 'get', token, null, params)
    //     const data = ErrorService.handleCommonError(res)
    //     return data;
    // }

    // // brand-------------------------------------------


    // // async searchCompany(token, params) {
    // //     const url = `${api.company}/search`
    // //     const res = await this.apiCall(url, 'post', token, params);
    // //     const data = ErrorService.handleCommonError(res)
    // //     return data;
    // // }

    // // record-------------------------------------------
    // async getRecord(token, key) {
    //     const url = `${api.record}/${key}`
    //     const res = await this.apiCall(url, 'get', token);
    //     const data = ErrorService.handleCommonError(res)
    //     return data;
    // }

    // async createRecord(token, params) {
    //     const res = await this.apiCall(api.record, 'post', token, params);
    //     const data = ErrorService.handleCommonError(res)
    //     return data;
    // }

    // async getRecords(token) {
    //     const res = await this.apiCall(api.record, 'get', token);
    //     const data = ErrorService.handleCommonError(res)
    //     return data;
    // }

    // async getItemRecords(token, key) {
    //     const url = `${api.item}/${key}/records`
    //     const res = await this.apiCall(url, 'get', token);
    //     const data = ErrorService.handleCommonError(res)
    //     return data;
    // }

    // async getManagerRecords(token, params) {
    //     const url = `${api.record}/manager_index`
    //     const res = await this.apiCall(url, 'get', token, null, params);
    //     const data = ErrorService.handleCommonError(res)
    //     return data;
    // }

    // async getCategoryManagerRecords(token, key, params) {
    //     const url = `${api.category}/${key}/manager_records`
    //     const res = await this.apiCall(url, 'get', token, null, params);
    //     const data = ErrorService.handleCommonError(res)
    //     return data;
    // }

    // async getItemRecords(token, key) {
    //     const url = `${api.item}/${key}/records`
    //     const res = await this.apiCall(url, 'get', token);
    //     const data = ErrorService.handleCommonError(res)
    //     return data;
    // }


    // // Category-------------------------------------------


    // // Comment-------------------------------------------

}

export default new ApiService()

export { ApiService }