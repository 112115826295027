import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import App from './router';

import { Provider } from 'mobx-react'
import store from './store'

import 'ant-design-pro/dist/ant-design-pro.css'
import "./index.less"

ReactDOM.render(
    <Provider {...store}>
        <App />
    </Provider>, 
    document.getElementById('root')
);

serviceWorker.unregister();

if (module.hot) {
  module.hot.accept();
}