import _ from 'lodash'
import { observable, action, runInAction } from 'mobx'

import AdminService from '../services/AdminService'
import BoardService from '../services/BoardService'
import StorageService from '../services/StorageService'

class Comment {

    getComments = async (recordId) => {
        const data = await BoardService.getComments(StorageService.getToken(), recordId)
        return data
    }
    
    getManagerComments = async (recordId) => {
        const data = await AdminService.getManagerComments(StorageService.getToken(), recordId)
        return data
    }

    createManagerComment = async (params) => {
        const proload = {
            comment: params
        }
        const data = await AdminService.createManagerComment(StorageService.getToken(), proload)
        return data
    }

    createComment = async (params) => {
        const proload = {
            comment: params
        }
        const data = await BoardService.createComment(StorageService.getToken(), proload)
        return data
    }

    destroyComment = async (key) => {
        const data = await BoardService.destroyComment(StorageService.getToken(), key)
        return data
    }
}

export default new Comment()