import _ from 'lodash'
import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react'

import { 
    Menu, 
    Icon,
} from 'antd';

import history from '../../router/history'

const MenuItemGroup = Menu.ItemGroup;

@inject('admin')
@observer
export default class SideMenu extends React.Component {

    onMenuClick = (e) => {
        history.push("/dashboard" + e.key)
    }

    render() {
        const {
            className,
        } = this.props;
        
        return (
            <Menu 
                key="Menu"
                mode="inline"
                defaultSelectedKeys={[]} 
                onClick={this.onMenuClick}
                className={classNames(className)}
            >
                <Menu.Item key="/">
                    <Icon type="medicine-box" />
                    <span>通知页</span>
                </Menu.Item>
                <Menu.Item key="/records">
                    <Icon type="solution" />
                    <span>我的记录</span>
                </Menu.Item>
                <Menu.Item key="/items">
                    <Icon type="contacts" />
                    <span>我的联系人</span>
                </Menu.Item>
                <Menu.Item key="/companies">
                    <Icon type="solution" />
                    <span>全部公司</span>
                </Menu.Item>
                <Menu.Item key="/all_items">
                    <Icon type="solution" />
                    <span>全部联系人</span>
                </Menu.Item>
                {
                    this.props.admin.isAuth("allocation") ?
                    <MenuItemGroup>
                        <Menu.Divider />
                        <Menu.Item key="/auth_list">
                            <Icon type="team" />
                            <span>权限</span>
                        </Menu.Item>
                    </MenuItemGroup>
                    : ""
                }
                {
                    this.props.admin.isAuth("export") ?
                    <MenuItemGroup>
                        <Menu.Divider />
                        <Menu.Item key="/export">
                            <Icon type="export" />
                            <span>导出数据</span>
                        </Menu.Item>
                    </MenuItemGroup>
                    :""
                }
                {
                    this.props.admin.isAuth("inspect") ? 
                        <MenuItemGroup>
                            <Menu.Divider/>
                            <Menu.Item key="/manager/companies">
                                <Icon type="solution" />
                                <span>全部公司</span>
                            </Menu.Item>
                            <Menu.Item key="/manager/items">
                                <Icon type="solution" />
                                <span>全部联系人</span>
                            </Menu.Item>
                            <Menu.Item key="/manager/records">
                                <Icon type="solution" />
                                <span>全部记录</span>
                            </Menu.Item>
                            <Menu.Item key="/manager/categories">
                                <Icon type="switcher" />
                                <span>公司分类</span>
                            </Menu.Item>
                            <Menu.Item key="/manager/tags">
                                <Icon type="switcher" />
                                <span>标签</span>
                            </Menu.Item>
                            <Menu.Item key="/manager_export">
                                <Icon type="solution" />
                                <span>全部导出</span>
                            </Menu.Item>
                        </MenuItemGroup>
                    : ""
                }
            </Menu>
        )
    }
}
