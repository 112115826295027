import admin from './admin'
import item from './item'
import company from './company'
import record from './record'
import user from './user'
import manager from './manager'
import comment from './comment'

export default {
    admin,
    item,
    record,
    company,
    user,
    manager,
    comment
}
