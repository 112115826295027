import React from 'react'
import classNames from 'classnames'
import {
    PageHeader
} from 'antd'

import CompanyEditForm from '../../../components/EditForm/company'


export default class CompanyNew extends React.Component {
    render() {
        return (
            <div className={classNames("small-content")}>
                <PageHeader
                    title="新建公司"
                    subTitle="创建公司信息"
                />
                <div className={classNames("content-info", "small-list")}>
                    <CompanyEditForm />
                </div>
            </div>
        )
    }
}