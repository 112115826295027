import _ from 'lodash'
import React from 'react';
import moment from 'moment'
import { inject, observer } from 'mobx-react'

import {
    Card,
    Descriptions,
    List,
    Button
} from 'antd';

import history from '../../router/history'
import UserTag from '../LinkTag/user'
import ItemTag from '../LinkTag/item'
import RecordDate from '../LinkTag/record_date'

@inject('record', 'user', 'admin')
@observer
class ItemReocrdsList extends React.Component {

    state = {
        records : []
    }

    componentDidMount() {
    }

    handleEditClick =  async (e, key) => {
        e.preventDefault();
        history.push(`/dashboard/records/${key}/edit`)
    }
    
    handleNewClick = (e) => {
        e.preventDefault()
        history.push("/dashboard/records_new?item="+this.props.itemId)
    }

    handleShowClick = (e, key) => {
        e.preventDefault()
        history.push("/dashboard/records/"+key)
    }

    render() {
        const { records } =this.props

        return (
            <div style={{width: 900}}>
                <Card>
                    <div>
                        <Button icon="plus" type="primary" onClick={this.handleNewClick}>
                            新建
                        </Button>
                    </div>
                    <div style={{marginTop: 10}}>
                        <List
                            className={"record-list"}
                            itemLayout="vertical"
                            pagination={{pageSize: 600}}
                            dataSource={records.slice()}
                            renderItem={record => {
                            const users = record.users|| []

                            return (
                                <List.Item
                                    key={record.id}
                                    actions={[
                                        <a href='javascript:;' onClick={(e)=>this.handleShowClick(e, record.id)}>详细</a>,
                                        <a href='javascript:;' onClick={(e)=>this.handleEditClick(e, record.id)}>编辑</a>
                                    ]}
                                >
                                    <List.Item.Meta 
                                        title={
                                            <div>
                                                <div className={"record_items"}>
                                                    {
                                                        record.items.map(item=>(<ItemTag key={item.id} item={item}/>))
                                                    }
                                                </div>
                                                <div className={"record_users"}>
                                                    {users.map(user=>(<UserTag  key={user.id} user={user} />))}
                                                </div>
                                            </div>
                                        }
                                        description={
                                            <RecordDate record_date={record.record_date} record_message={record.message} />
                                        }
                                    />
                                        <div>
                                            <Descriptions size="large" column={1}>
                                                <Descriptions.Item label="沟通目的"><pre><span dangerouslySetInnerHTML={{ __html: record.message["purpose"]}}/></pre></Descriptions.Item>
                                                <Descriptions.Item label="沟通准备"><pre><span dangerouslySetInnerHTML={{ __html: record.message["prepare"]}}/></pre></Descriptions.Item>
                                                <Descriptions.Item label="沟通记录"><pre><span dangerouslySetInnerHTML={{ __html: record.message["content"]}}/></pre></Descriptions.Item>
                                                {
                                                    record.message["next_time"] ? <Descriptions.Item label="计划下次拜访">{moment(record.message["next_time"]).format('YYYY-MM-DD')} - {moment(record.message["next_time"]).fromNow()}</Descriptions.Item> : "11"
                                                }
                                                {
                                                    record.message["plan"] ? <Descriptions.Item label="计划和反馈"><pre><span dangerouslySetInnerHTML={{ __html: record.message["plan"]}}/></pre></Descriptions.Item> : ""
                                                }
                                            </Descriptions>
                                        </div>
                                </List.Item>
                            )}}/>
                    </div>
                    共{records.length||0}条记录
                </Card>
            </div>
        )
    }
}

export default ItemReocrdsList;