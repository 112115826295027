import React from 'react';
import DocumentTitle from 'react-document-title';
import { 
    Layout,
} from 'antd';

import logo from '../assets/logo.svg';
import SiderMenu from '../components/SiderMenu';
import Header from './HeaderView';

import RouteWithSubRoutes from "../router/routewithsubroutes"

import styles from './BasicLayout.module.less';

const { Content } = Layout;

export default class BasicLayout extends React.Component {

    render() {
        const { routes } = this.props;

        const layout = (            
            <Layout
                style={{ height: "100%" }}
            >
                <SiderMenu
                    logo={logo}
                />
                <Layout style={{ marginLeft: 200, height: "100%" }}>
                    <Header style={{ background: '#fff', padding: 0 }} />
                    <Content className={styles.content}>
                        {routes.map((route, i) => (<RouteWithSubRoutes key={i} {...route} />))}
                    </Content>
                </Layout>
            </Layout>)

        return (
            <React.Fragment>
                <DocumentTitle title={"idaily-crm"}>
                    {layout}
                </DocumentTitle>
            </React.Fragment>
        )
    }
}