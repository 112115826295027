import _ from 'lodash'
import React from 'react';
import { inject, observer } from 'mobx-react'
import { 
    Menu, 
    Select
} from 'antd';
import HeaderSearch from 'ant-design-pro/lib/HeaderSearch';

import history from '../../router/history'

import styles from './GlobalHeader.module.less';

const Option = Select.Option;

@inject('admin', 'item')
@observer
class GlobalHeader extends React.Component {

    state = {
        items: []
    }

    onSearch = async (value) => {
        if (!_.isEmpty(value)) {
            const data  = await this.props.item.searchItem(value)
            this.setState({
                items: data.map(item=>item.info.name)
            })
        } else {
            this.setState({
                items: []
            })
        }
    }

    onPressEnter = (value) => {
        this.props.item.getSearchItems({query: value})
        history.push('/dashboard/search_items?query='+value)
    }

    render() {
        const {items} = this.state

        return (
            <div className={styles.header}>
                <div className={styles.light}>
                    <HeaderSearch
                        className={`${styles.action} ${styles.search}`}
                        placeholder="站内搜索"
                        dataSource={items}
                        onSearch={this.onSearch}
                        onPressEnter={this.onPressEnter}
                    />
                </div>
            </div>
    )}
}

export default GlobalHeader;
