import React from 'react'
import classNames from 'classnames'

import {
    PageHeader,
} from 'antd'

import UserEditForm from '../../../components/EditForm/item'

export default class ItemEdit extends React.Component {

    render() {
        return (
            <div className={classNames("small-content")}>
                <PageHeader
                    // onBack={() => null}
                    title="编辑联系人"
                    subTitle="修改联系人"
                />
                <div className={classNames("content-info", "small-list")}>
                    <UserEditForm itemId={this.props.match.params.id}/>
                </div>
            </div>
        )
    }
}