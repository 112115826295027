import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'

import {
    Card,
    PageHeader,
    Table,
    Tag,
    Button,
    Checkbox, 
    Row, 
    Col
} from 'antd'

const { Column } = Table;

@inject('item')
@observer
export default class ExportData extends React.Component {

    state = {
        selectedRowKeys: [],
        columns: []
    }

    componentDidMount() {
        this.props.item.getManagerItems()
    }

    handleExport = () => {
        const proload = {
            items: this.state.selectedRowKeys,
            columns: this.state.columns
        }
        this.props.item.exportManagerCsv(proload)
    }

    handleChange = (e) => {
        this.setState({columns: e})
    }

    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({
                selectedRowKeys: selectedRowKeys
            })
        },
    };

    render() {
        const { manager_items } = this.props.item
        return (
            <div>
                <PageHeader
                    // onBack={() => null}
                    title="数据导出"
                    subTitle="导出全部数据"
                />
                <div className={classNames("content-info", "big-list")}>
                    <Card bordered={false} style={{marginTop:"10px"}}>
                        <span>
                            <Checkbox.Group style={{ width: '100%' }} onChange={this.handleChange}>
                                <Row>
                                    <Col span={4}><Checkbox value="name">姓名</Checkbox></Col>
                                    <Col span={4}><Checkbox value="company">公司</Checkbox></Col>
                                    <Col span={4}><Checkbox value="job">部门</Checkbox></Col>
                                    
                                    <Col span={4}><Checkbox value="private_phone">个人联系方式</Checkbox></Col>
                                    <Col span={4}><Checkbox value="Doffice_phone">公司联系方式</Checkbox></Col>
                                    <Col span={4}><Checkbox value="delivery_address">快递地址</Checkbox></Col>
                                    <Col span={4}><Checkbox value="qq">qq</Checkbox></Col>
                                    <Col span={4}><Checkbox value="wechat">微信</Checkbox></Col>
                                    <Col span={4}><Checkbox value="birthday">生日</Checkbox></Col>
                                </Row>
                            </Checkbox.Group>
                        </span>
                        <span><Button onClick={this.handleExport}>导出</Button></span>
                    </Card>

                    <Card bordered={false} style={{marginTop:"10px"}} >
                        <Table 
                            dataSource={manager_items}
                            rowSelection={this.rowSelection}
                            rowKey={record=>record.id}
                            pagination={{ pageSize: 600 }}
                        >
                            <Column
                                title="公司"
                                key="company"
                                width={250}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) =>( (a.company.info ? a.company.info.name : "").localeCompare(b.company.info ? b.company.info.name : ""))}
                                render={(data, record) => {
                                    const company_info = record.company ? record.company.info : {}
                                    return (
                                        <span>{company_info.name || ""}</span>
                                    )
                                }}
                            />
                            <Column
                                title="姓名"
                                key="name"
                                width={300}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => a.info.name.localeCompare(b.info.name)}
                                render={(data, record) => {
                                    return (
                                        <span>{record.info.name}</span>
                                    )
                                }}
                            />
                            <Column
                                title="负责人"
                                key="auth_users"
                                width={100}
                                render={(data, record) => {
                                    const auth_users = record.auth_users || []
                                    return (
                                        auth_users.map(user=><Tag key={user.id}>{user.nickname}</Tag>)
                                    )
                                }}
                            />
                            <Column
                                title="重要度"
                                key="score"
                                width={120}
                                filters={
                                    [{
                                        text: '1星',
                                        value: 1,
                                    },{
                                        text: "2星",
                                        value: 2,
                                    },{
                                        text: "3星",
                                        value: 3,
                                    },{
                                        text: "4星",
                                        value: 4,
                                    },{
                                        text: "5星",
                                        value: 5,
                                    }]
                                }
                                onFilter={(value, record) => record.info.score == value}
                                filterMultiple={false}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => a.info.score - b.info.score}
                                render={(data, record) => {
                                    return (
                                        <span>{record.info.score||""}</span>
                                    )
                                }}
                            />
                            <Column
                                title="职位"
                                key="job"
                                width={150}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => a.info.job.localeCompare(b.info.job)}
                                render={(data, record) => {
                                    return (
                                        <span>{record.info.job}</span>
                                    )
                                }}
                            />
                            <Column
                                title="创建时间"
                                key="created_at"
                                width={150}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => a.created_at - b.created_at}
                                render={(data, record) => {
                                    return (
                                        <span>{moment(record.created_at).format("YYYY-MM-DD")}</span>
                                    )
                                }}
                            />
                        </Table>
                        共{manager_items.length}位客户
                    </Card>
                </div>
            </div>
        )
    }
}