import _ from 'lodash'
import React from 'react';
import { inject, observer } from 'mobx-react'

import { 
    Card,
    Form, 
    Input, 
    Button, 
    Select,
    Divider,
    InputNumber,
} from 'antd';

import history from '../../router/history'

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

@inject('company', 'manager')
@observer
class CompanyEditForm extends React.Component {

    state = {
        companyId: this.props.companyId,
        company: {},
        info:{},
        associate: false
    }

    componentDidMount() {
        this.init()
    }

    init = async () => {
        const companyId = this.props.companyId
        this.props.manager.getCategories()
        this.props.company.getCompanies()
        if(!_.isUndefined(companyId)) {
            const data = await this.props.company.getCompany(companyId)
            if(!_.isNull(data)){
                this.setState({
                    company: data,
                    info: data.info,
                    associate: data.category.associate
                })
            }
        }
    }

    change_category = (id) => {
        const index =  _.findIndex(this.props.manager.categories, {id: id})
        if(index > -1) {
            const category = this.props.manager.categories[index]
            this.setState({
                associate: category.associate
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if(!_.isUndefined(this.props.companyId)) {
                    this.props.company.updateCompany(this.props.companyId, {company: values})
                } else {
                    this.props.company.createCompany({company: values})
                }
                history.push('/dashboard/companies')
            }
        });
    }

    handleFilter = (input, option) => {
        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    handleBack = (e) => {
        e.preventDefault();
        history.push("/dashboard/manager/companies")
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { company, info, associate } = this.state
        const companies = this.props.company.companies || []
        const { categories } = this.props.manager

        return (
            <Card className="company-form" style={{width: "900px"}}>
                <Form onSubmit={this.handleSubmit} 
                    style={{ marginTop: 20, marginBottom: 10 }}
                    autoComplete="off"
                >
                    <Divider orientation="left">公司基本信息</Divider>
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="公司名称"
                        
                        help={"中文/英文, /为分割符"}
                    >
                        {getFieldDecorator('info[name]', {
                            rules: [{
                                required: true, message: '请输入公司名称, 中文/英文',
                            }],
                            initialValue: info.name || ""
                        })(<Input placeholder="请输入公司名称" />)}
                    </FormItem>
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="公司种类"
                        
                    >
                        {getFieldDecorator('category_id', {
                            rules: [{
                                required: true, message: '请输入公司种类',
                            }],
                            initialValue: company.category_id || ""
                        })(
                            <Select onChange={this.change_category}>
                                {
                                    categories.map(key=>(
                                        <Option key={key.id} value={key.id}>{key.name}</Option>
                                    ))
                                }
                            </Select>
                        )}
                    </FormItem>
                    {
                        associate ? 
                        <FormItem
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 10 }}
                            label="关联公司"
                            
                        >
                            {getFieldDecorator('associate_ids', {
                                initialValue: company.connection_ids||[]
                            })(
                                <Select 
                                    showSearch
                                    mode="multiple"
                                    showArrow={false}
                                    filterOption={this.handleFilter}
                                    optionLabelProp="label"
                                >
                                    {
                                        companies.map(company1=>(
                                            company.id != company1.id ?
                                                <Option key={company1.id} value={company1.id} label={company1.info["name"]}>
                                                    <div className="item-select">
                                                        <span className="label">{company1.info["name"]}</span>
                                                        <span className="caption">{company1.category.name}</span>
                                                    </div>
                                                </Option>
                                                : ""
                                        ))
                                    }
                                </Select>
                            )}
                        </FormItem> : ""
                    }
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="创始年份"
                    >
                        {getFieldDecorator('info[founding_year]', {
                            initialValue: info.founding_year || 1900
                        })(<InputNumber/>)}
                    </FormItem>
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="国家"
                    >
                        {getFieldDecorator('info[country]', {
                            initialValue: info.country || ""
                        })(<Input placeholder="请输入国家" />)}
                    </FormItem>
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="公司官网"
                        
                    >
                        {getFieldDecorator('url', {
                            initialValue: info.url || ""
                        })(<Input placeholder="请输入网址" />)}
                    </FormItem>
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="PR邮箱"
                    >
                        {getFieldDecorator('info[pr_email]', {
                            initialValue: info.pr_email || ""
                        })(<Input placeholder="请输入邮件地址" />)}
                    </FormItem>
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="微博"
                        
                    >
                        {getFieldDecorator('info[weibo]', {
                            initialValue: info.weibo || ""
                        })(<Input placeholder="请输入网址" />)}
                    </FormItem>
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="微信"
                        
                    >
                        {getFieldDecorator('info[wechat]', {
                            initialValue: info.wechat || ""
                        })(<Input placeholder="请输入账号" />)}
                    </FormItem>
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="Instagram"
                        
                    >
                        {getFieldDecorator('info[ins]', {
                            initialValue: info.ins || ""
                        })(<Input placeholder="请输入网址" />)}
                    </FormItem>

                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 15 }}
                        label="备注"
                    >
                        {getFieldDecorator('info[remark]', {
                            initialValue: info.remark||""
                        })(
                            <TextArea  autosize={{ minRows: 3}} />
                        )}
                    </FormItem>
                    <FormItem
                        wrapperCol={{ span: 12, offset: 3 }}
                    >
                        <Button type="primary" htmlType="submit">提交</Button>
                        <Button style={{marginLeft: 15}} onClick={this.handleBack}>返回上一级</Button>
                    </FormItem>
                </Form>
            </Card>
        )
    }
}

export default  Form.create()(CompanyEditForm);