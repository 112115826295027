import _ from 'lodash'
import { observable, action, runInAction } from 'mobx'

import ApiService from '../services/ApiService'
import AuthService from '../services/AuthService'
import AdminService from '../services/AdminService'
import BoardService from '../services/BoardService'

import StorageService from '../services/StorageService'
import { async } from 'q';

class Item {
    @observable items = []
    @observable all_items = []
    @observable manager_items = []
    @observable search_items = []
    @observable auth_items = []

    @action
    getItems = async () => {
        const data = await BoardService.getItems(StorageService.getToken())
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.items = data
            })
        }
    }

    @action
    getAllItems = async () => {
        const data = await BoardService.getAllItems(StorageService.getToken())
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.all_items = data
            })
        }
    }

    @action
    getSearchItems = async (params) => {
        const preload = {
            "query": params["query"] || ""
        }
        const data = await BoardService.getAllItems(StorageService.getToken(), preload)
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.search_items = data
            })
        }
    }

    getItem = async (key) => {
        const item = await BoardService.getItem(StorageService.getToken(), key)
        return item
    }

    getCompanyItems = async (key) => {
        const data = await BoardService.getCompanyItems(StorageService.getToken(), key)
        return data
    }


    @action
    getCategoryItems = async (categoryId) => {
        const data = await BoardService.getCategoryItems(StorageService.getToken(), categoryId)
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.items = data
            })
        }
    }

    @action
    getCategoryAllItems = async (categoryId) => {
        const data = await BoardService.getCategoryAllItems(StorageService.getToken(), categoryId)
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.all_items = data
            })
        }
    }

    @action
    getCategorySearchItems = async (categoryId, params={}) => {
        const preload = {
            "query": params["query"] || ""
        }

        const data = await BoardService.getCategoryAllItems(StorageService.getToken(), categoryId, preload)
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.search_items = data
            })
        }
    }



    @action
    createItem = async (params) => {
        const data = await BoardService.createItem(StorageService.getToken(), params)
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.items.push(data)
            })
        }
        return data.id
    }

    @action
    updateItem = async (key, params) => {
        const data = await BoardService.updateItem(StorageService.getToken(), key, params)
        if(!_.isNull(data)) {
            runInAction(()=>{
            const index = _.findIndex(this.items, {id: data.id})
                if(index > -1) {
                    this.items[index] = data
                }
            })
        }
    }

    @action
    destroyItem = async (key) => {
        await BoardService.destroyItem(StorageService.getToken(), key)
        const index = _.findIndex(this.manager_items, {id: parseInt(key)})
        if(index > -1) {
            runInAction(()=>{
                this.manager_items.splice(index,1)
            })
        }
    }

    searchItem = async (query) => {
        const params = {
            item: query
        }
        const data = await BoardService.searchItem(StorageService.getToken(), params)
        return data
    }

    exportCsv = async (params) => {
        const data = await BoardService.exportCsv(StorageService.getToken(), params)
        let url = window.URL.createObjectURL(new Blob([data]));
        let a = document.createElement('a');
        a.href = url;
        a.download = "data.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    @action
    getAuthItems = async () => {
        const data = await AuthService.getAuthItems(StorageService.getToken())
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.auth_items = data
            })
        }
    }

    @action
    addAuth = async (params) => {
        
        const data = await AuthService.addAuth(StorageService.getToken(), params)
        if(!_.isNull(data)) {
            runInAction(()=>{
                data.map(item=>{
                    const index = _.findIndex(this.auth_items, {id: parseInt(item.id)})
                    if(index > -1) {
                        this.auth_items[index] = item
                    }
                })
            })
        }
    }

    @action
    removeAuth = async (params) => {
        const data = await AuthService.removeAuth(StorageService.getToken(), params)
        if(!_.isNull(data)) {
            runInAction(()=>{
                data.map(item=>{
                    const index = _.findIndex(this.auth_items, {id: parseInt(item.id)})
                    if(index > -1) {
                        this.auth_items[index] = item
                    }
                })
            })
        }
    }

    @action
    moveAuth = async (params) => {
        const data = await AuthService.moveAuth(StorageService.getToken(), params)
        if(!_.isNull(data)) {
            runInAction(()=>{
                data.map(item=>{
                    const index = _.findIndex(this.auth_items, {id: parseInt(item.id)})
                    if(index > -1) {
                        this.auth_items[index] = item
                    }
                })
            })
        }
    }

    exportManagerCsv = async (params) => {
        const data = await AdminService.exportCsv(StorageService.getToken(), params)
        let url = window.URL.createObjectURL(new Blob([data]));
        let a = document.createElement('a');
        a.href = url;
        a.download = "data.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    getManagerItem = async (key) => {
        const item = await AdminService.getManagerItem(StorageService.getToken(), key)
        return item
    }

    @action
    getManagerItems = async () => {
        const data = await AdminService.getManagerItems(StorageService.getToken())
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.manager_items = data
            })
        }
    }

    @action
    getManagerCategoryItems = async (categoryId, params) => {
        const data = await AdminService.getManagerCategoryItems(StorageService.getToken(), categoryId, params)
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.manager_items = data
            })
        }
    }

    getItemLogs = async (item_id) => {
        const data = await AdminService.getItemLogs(StorageService.getToken(), item_id)
        return data
    }

}

export default new Item()