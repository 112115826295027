import React from 'react'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'

import { Card, List, Button, PageHeader } from 'antd';

import history from '../../../router/history'

@inject('manager')
@observer
export default class TagList extends React.Component {

    componentDidMount() {
        this.props.manager.getTags()
    }

    handleOnClick = () => {
        history.push('/dashboard/manager/tags_new')
    }

    handleDestroyClick = (e, key) => {
        this.props.manager.destroyTag(key)
    }

    handleEditClick = (e, key) => {
        history.push('/dashboard/manager/tags/'+key+'/edit')
    }

    render() {
        const { tags } = this.props.manager

        return (
            <div className={classNames("minimum-content")}>
                <PageHeader
                    title="标签列表"
                    subTitle="This is a subtitle"
                />
                <div className={classNames("content-info", "minimum-list")}>
                    <Card bordered={false} >
                        <Button onClick={this.handleOnClick} >新建</Button>
                        <List
                            itemLayout="horizontal"
                            dataSource={tags.slice()}
                            renderItem={tag => (
                                <List.Item
                                    key={tag.id}
                                    actions={[
                                            <a href='javascript:;' onClick={(e)=>this.handleEditClick(e, tag.id)}>编辑</a>,
                                            <a href='javascript:;' onClick={(e)=>this.handleDestroyClick(e, tag.id)}>删除</a>
                                        ]}
                                >
                                    <List.Item.Meta
                                        title={tag.name}
                                        description={"优先级:"+tag.priority}
                                    />
                                </List.Item>
                            )}
                        />
                    </Card>
                </div>
            </div>
        )
    }
}