import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import queryString from 'query-string'
import { inject, observer } from 'mobx-react'

import {
    Card,
    Button,
    PageHeader,
    Table,
    Tag,
    Divider,
} from 'antd'

import history from '../../../router/history'
import config from '../../../config'

const { Column } = Table;
const CheckableTag = Tag.CheckableTag;

@inject('item', 'admin', 'manager')
@observer
export default class ItemSearch extends React.Component {

    state = {
        selectedTag: null,
    };

    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search)
        this.props.item.getSearchItems(parsed)
        this.props.manager.getCategories()
    }

    handleEditClick = (e,key) => {
        e.preventDefault()
        history.push("/dashboard/items/"+key+"/edit")
    }

    handleNewUser = (e) => {
        e.preventDefault()
        history.push("/dashboard/items_new")
    }

    handleNewRecordClick = (e, key) => {
        e.preventDefault()
        history.push("/dashboard/records_new?item="+key)
    }

    handleChange(tag, checked) {
        if(checked){
            const parsed = queryString.parse(this.props.location.search)
            this.props.item.getCategorySearchItems(tag, parsed)
            this.setState({ selectedTag: tag });
        } else {
            const parsed = queryString.parse(this.props.location.search)
            this.props.item.getSearchItems(parsed)
            this.setState({ selectedTag: null });
        }
    }

    render() {
        const { search_items } = this.props.item
        const { selectedTag } = this.state;
        const { categories } = this.props.manager

        return (
            <div className={classNames("big-content")}>
                <PageHeader
                    title="搜索结果"
                    subTitle="搜索结果"
                />
                <div className={classNames("content-info", "big-list")}>
                    <Card bordered={false} style={{marginTop:"10px"}} >
                        <div className="category">
                            <h4>分类:</h4>
                            <div>
                                {categories.map(tag => (
                                    <CheckableTag
                                        key={tag.id}
                                        checked={selectedTag == tag.id ? !!selectedTag : false}
                                        onChange={checked => this.handleChange(tag.id, checked)}
                                    >
                                        {tag.name}
                                    </CheckableTag>
                                ))}
                            </div>
                        </div>
                    </Card>


                    <Card bordered={false} style={{marginTop:"10px"}} >
                        {
                            this.props.admin.isAuth("entering") ?
                            <div>
                                <Button icon="plus" type="primary" onClick={this.handleNewUser}>
                                    新建
                                </Button>
                            </div> 
                            :""
                        }
                        <Table 
                            dataSource={search_items.slice()}
                            rowKey={record=>record.id}
                            pagination={{ pageSize: 600 }}
                        >
                            <Column
                                title="公司"
                                key="company"
                                width={250}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) =>( (a.company.info ? a.company.info.name : "").localeCompare(b.company.info ? b.company.info.name : ""))}
                                render={(data, record) => {
                                    const company_info = record.company.info || {}
                                    return (
                                        <span>
                                            <a href={record.company ? config.CLIENT_URL + '/dashboard/companies/' + record.company.id : ""}>
                                                {company_info.name||""}
                                            </a>
                                        </span>
                                    )
                                }}
                            />
                            <Column
                                title="职位"
                                key="job"
                                width={250}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => a.info.job.localeCompare(b.info.job)}
                                render={(data, record) => {
                                    return (
                                        <span>{record.info.job}</span>
                                    )
                                }}
                            />
                            <Column
                                title="姓名"
                                key="name"
                                width={200}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => a.info.name.localeCompare(b.info.name)}
                                render={(data, record) => {
                                    return (
                                        <span>
                                            <a href={config.CLIENT_URL + '/dashboard/items/' + record.id}>
                                                {record.info.name}
                                            </a>
                                        </span>
                                    )
                                }}
                            />
                            <Column
                                title="负责人"
                                key="auth_users"
                                width={100}
                                // sortDirections={['descend', 'ascend']}
                                // sorter={(a, b) => a.info.name.localeCompare(b.info.name)}
                                render={(data, record) => {
                                    const auth_users = record.auth_users || []
                                    return (
                                        auth_users.map(user=><Tag key={user.key}>{user.label}</Tag>)
                                    )
                                }}
                            />
                            <Column
                                title="重要度"
                                key="score"
                                width={120}
                                filters={
                                    [{
                                        text: '1星',
                                        value: 1,
                                    },{
                                        text: "2星",
                                        value: 2,
                                    },{
                                        text: "3星",
                                        value: 3,
                                    },{
                                        text: "4星",
                                        value: 4,
                                    },{
                                        text: "5星",
                                        value: 5,
                                    }]
                                }
                                onFilter={(value, record) => record.info.score == value}
                                filterMultiple={false}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => a.info.score - b.info.score}
                                render={(data, record) => {
                                    return (
                                        <span>{record.info.score||""}</span>
                                    )
                                }}
                            />
                            <Column
                                title="距离上次访问"
                                key="latest_record"
                                width={150}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => (a.info.latest_record||0) - (b.info.latest_record||0)}
                                render={(data, record) => {
                                    return (
                                        <span>{record.latest_record ? moment(record.latest_record).fromNow() : "最近没有访问"}</span>
                                    )
                                }}
                            />
                            <Column
                                title="创建时间"
                                key="created_at"
                                width={150}
                                sortDirections={['descend', 'ascend']}
                                sorter={(a, b) => a.created_at - b.created_at}
                                render={(data, record) => {
                                    return (
                                        <span>{moment(record.created_at).format("YYYY-MM-DD")}</span>
                                    )
                                }}
                            />
                            <Column
                                title="Action"
                                key="action"
                                render={(data) => (
                                    <span>
                                        <a href='javascript:;' onClick={(e)=>this.handleNewRecordClick(e, data.id)}>添加记录</a>
                                        <Divider type="vertical" />
                                        <a href='javascript:;' onClick={(e)=>this.handleEditClick(e, data.id)}>编辑</a>
                                    </span>
                                )}
                            />
                        </Table>
                        共{search_items.length}位客户
                    </Card>
                </div>
            </div>
        )
    }
}