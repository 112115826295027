import React from 'react';
import classNames from 'classnames'
import { PageHeader } from 'antd';

import CategoryEditForm from '../../../components/EditForm/category'

export default class CategoryNew extends React.Component {
    render() {
        return (
            <div className={classNames("minimum-content")}>
                <PageHeader
                    title="新分类"
                    subTitle="This is a subtitle"
                />
                <div className={classNames("content-info", "minimum-list")}>
                    <CategoryEditForm />
                </div>
            </div>
        )
    }
}