import _ from 'lodash'
import React from 'react';
import { inject, observer } from 'mobx-react'
import { 
    Form, 
    Input, 
    Button, 
    Card,
    InputNumber
} from 'antd';

import history from '../../router/history'

const FormItem = Form.Item;

@inject('manager')
@observer
class TagEditForm extends React.Component {

    state = {
        tag: {},
    }

    componentDidMount() {
        this.init()
    }

    init = async () => {
        const tagId = this.props.tagId
        if(!_.isUndefined(tagId)) {
            const data = await this.props.manager.getTag(tagId)
            if(!_.isNull(data)){
                this.setState({
                    tag: data,
                })
            }
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if(!_.isUndefined(this.props.tagId)) {
                    this.props.manager.updateTag(this.props.tagId, values)
                } else {
                    this.props.manager.createTag(values)
                }
            }
            history.push('/dashboard/manager/tags')
        });
    }

    handleBack = (e) => {
        e.preventDefault();
        history.push("/dashboard/manager/tags")
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const tag = this.state.tag

        return (
            <Card style={{width: "600px"}}>
                <Form onSubmit={this.handleSubmit} 
                    style={{ marginTop: 20 }}
                    autoComplete="off"
                >
                        <FormItem
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 10 }}
                            label="标签"
                        > 
                            {getFieldDecorator('name', {
                                initialValue: tag.name||""
                            })(<Input placeholder="请输入标签" />)}
                        </FormItem>
                        <FormItem
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 10 }}
                            label="优先级"
                        >   
                            {getFieldDecorator('priority', {
                                initialValue: tag.priority||0
                            })(<InputNumber min={0} max={10} />)}
                        </FormItem>
                    <FormItem
                        wrapperCol={{ span: 12, offset: 3 }}
                    >
                        <Button type="primary" htmlType="submit">提交</Button>
                        <Button onClick={this.handleBack}>返回上一级</Button>
                    </FormItem>
                </Form>
            </Card>
        )
    }
}

export default  Form.create()(TagEditForm);