import _ from 'lodash'
import { observable, action, runInAction } from 'mobx'

import ApiService from '../services/ApiService'
import StorageService from '../services/StorageService'


class User {
    @observable users = []

    @action
    getUsers = async () => {
        const data = await ApiService.getUsers(StorageService.getToken())
        if(!_.isNull(data)) {
            runInAction(()=>{
                this.users = data
            })
        }
    }

    getUserById = (id) => {
        const index = _.findIndex(this.users, {id: parseInt(id)})
        if(index > -1) {
            return this.users[index]
        } else {
            return null
        }
    }
}

export default new User() 