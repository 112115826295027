import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'

import {
    Card,
    Table,
    Button,
    Divider,
    Tag,
    PageHeader
} from 'antd'

import history from '../../../router/history'

const { Column } = Table;
const CheckableTag = Tag.CheckableTag;

@inject('company', 'manager', 'admin')
@observer
export default class Companies extends React.Component {

    state = {
        selectedTag: null,
    };

    componentDidMount() {
        this.props.company.getCompanies()
        this.props.manager.getCategories()
    }

    handleChange(tag, checked) {
        if(checked){
            this.props.company.getCompanies({ "category": tag })
            this.setState({ selectedTag: tag });
        } else {
            this.props.company.getCompanies()
            this.setState({ selectedTag: null });
        }
    }

    handleNewClick = (e,key) => {
        e.preventDefault()
        history.push("/dashboard/items_new?company="+key)
    }

    handleNewBrand = (e) => {
        e.preventDefault()
        history.push("/dashboard/companies_new")
    }

    handleEditClick = (e,key) => {
        e.preventDefault()
        history.push("/dashboard/companies/"+key+"/edit")
    }

    handleJumpCompany = (e, key) => {
        e.preventDefault()
        history.push("/dashboard/companies/"+key)
    }


    render() {
        const { companies } = this.props.company
        const { selectedTag } = this.state
        const { categories } = this.props.manager

        return (
            <div className={classNames("median-content")}>
                <PageHeader
                    title="公司列表"
                    subTitle="This is a subtitle"
                />
                <div className={classNames("content-info", "median-list")}>

                    <Card bordered={false} style={{marginTop:"10px"}} >
                        <div className="category">
                            <h4>分类:</h4>
                            <div>
                                {categories.map(tag => (
                                    <CheckableTag
                                        key={tag.id}
                                        checked={selectedTag == tag.id ? !!selectedTag : false}
                                        onChange={checked => this.handleChange(tag.id, checked)}
                                    >
                                        {tag.name}
                                    </CheckableTag>
                                ))}
                            </div>
                        </div>
                    </Card>
                    <Card bordered={false} style={{marginTop:"10px"}}>
                        <Button icon="plus" type="primary" onClick={this.handleNewBrand}>
                            新建
                        </Button>
                        <div>
                            <Table 
                                dataSource={companies.slice()}
                                rowKey={record=>record.id}
                                pagination={{ pageSize: 600 }}
                            >  
                                <Column
                                    title="分类"
                                    key="category"
                                    width={250}
                                    render={(data, record) => {
                                        return (
                                            <Tag>{record.category.name}</Tag>
                                        )
                                    }}
                                />
                                <Column
                                    title="名称"
                                    key="name"
                                    width={300}
                                    render={(data, record) => {
                                        return (
                                            <a href='javascript:;' onClick={(e)=>this.handleJumpCompany(e, record.id)} >
                                               <div>{record.info["name"]}</div> 
                                            </a>
                                        )
                                    }}
                                />
                                <Column
                                    title="人数"
                                    dataIndex="item_count"
                                    key="item_count"
                                    width={80}
                                />
                                <Column
                                    title="创建时间"
                                    key="created_at"
                                    width={150}
                                    render={(data, record) => {
                                        return (
                                            <div>{moment(record.created_at).format("YYYY-MM-DD")}</div> 
                                        )
                                    }}
                                />
                                <Column
                                    title="修改时间"
                                    key="updated_at"
                                    width={150}
                                    render={(data, record) => {
                                        return (
                                            <div>{moment(record.updated_at).format("YYYY-MM-DD")}</div> 
                                        )
                                    }}
                                />
                                <Column
                                    title="操作"
                                    key="action"
                                    render={(data) => (
                                        <span>
                                            <a href='javascript:;' onClick={(e)=>this.handleNewClick(e, data.id)}>新建联系人</a>
                                            <Divider type="vertical" />
                                            {
                                                this.props.admin.isAuth("export") ? <a href='javascript:;' onClick={(e)=>this.handleEditClick(e, data.id)}>修改</a> : ""
                                            }
                                        </span>
                                    )}
                                />
                            </Table>
                        </div>
                        <div>{companies.length || 0}个公司</div>
                    </Card>
                </div>
            </div>
        )
    }
}