import _ from 'lodash'
import { observable, action, runInAction } from 'mobx'

import ApiService from '../services/ApiService'
import StorageService from '../services/StorageService'

import history from '../router/history'

class AdminStore {

    @observable auth_token = ""
    @observable user = {}
    @observable isLoginStaus = false

    @action 
    login = async (params) => {
        const data = await ApiService.login(params)
        if(!_.isNull(data)) {
            const token = data["token"]
            const user = data
            StorageService.setToken(token)
            StorageService.setUser(data)
            runInAction(()=>{
                this.isLoginStaus = true
                this.auth_token = token
                this.user = user
            })
            history.push("/dashboard/")
        }
    }

    @action
    getStatus = () => {
        if(this.isLoginStaus && !_.isEmpty(this.auth_token)) {
            return true
        } else if (StorageService.getToken()) {
            const token = StorageService.getToken()
            const user = StorageService.getUser()

            runInAction(() => {
                this.loginStatus = true
                this.auth_token = token
                this.user = user
                this.name = user.nickname
                this.avatar = user.avatar
            })
            return true
        } else {
            return false
        }
    }

    @action
    logout = () => {
        StorageService.removeAll()
        runInAction(() => {
            this.isLogin = false
            this.auth_token = ""
            this.user = {}
        })
        history.push('/login')
    }

    isAuth = (auth) => {
        const authData = this.user.auth || []
        return authData.indexOf(auth) > -1 
    }
}

export default new AdminStore();