import React from 'react'
import classNames from 'classnames'

import {
    PageHeader
} from 'antd';

export default class Info extends React.Component {

    render() {
        return (
            <div>
                <PageHeader
                    title="通知页"
                    subTitle="我的通知"
                />
                <div className={classNames("content-info", "median-list")}>
                    <div>1111111111</div>
                </div>
            </div>
        )
    }
}