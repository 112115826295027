import React from 'react'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'

import ItemCard from '../../../components/ShowCard/ItemCard'
import CommentList from '../../../components/CommentList'
import ItemReocrdsList from '../../../components/ListTable/item_records'

import {
    PageHeader
} from 'antd'


@inject('item', 'record')
@observer
export default class ItemShow extends React.Component {

    state = {
        item: {},
        records: []
    }

    componentDidMount() {
        this.init()
    }

    init = async () => {
        const itemId = this.props.match.params.id
        const data = await this.props.item.getItem(itemId)
        if(!_.isNull(data)) {
            const records = await this.props.record.getItemRecords(itemId)
            this.setState({
                item: data,
                records: records
            })
        }
    }

    handleDeleteClick = (e, key) => {
        e.preventDefault()
        this.props.record.destroyRecord(key)
        const new_reords = this.state.records.filter(record =>(record.id != key))
        this.setState({
            records: new_reords,
        })
    }

    render() {
        const { item, records } = this.state
        
        return (
            <div className={classNames("big-content")}>
                <PageHeader
                    title="详细信息"
                    subTitle="联系人详细信息和记录"
                />
                <div className={classNames("content-info", "big-list", "show-info")}>
                    <div>
                        <div> 
                            <ItemCard item={item}/>
                        </div>
                    </div>
                    <div style={{marginLeft: 20}}>
                        <ItemReocrdsList itemId={this.props.match.params.id} records={records} handleDelete={this.handleDeleteClick}/>
                    </div>
                </div>
            </div>
        )
    }
}