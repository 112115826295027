import _ from 'lodash'
import React from 'react';
import moment from 'moment'
import { inject, observer } from 'mobx-react'

import { 
    DatePicker,
    Form, 
    Input, 
    Button, 
    Select,
    Divider,
    Radio,
    Rate,
    Card,
    Icon,
} from 'antd';


import history from '../../router/history'
import "./item.less"

const RadioGroup = Radio.Group;

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const { MonthPicker } = DatePicker;

@inject('item', 'company', 'manager')
@observer
class UserEditForm extends React.Component {

    state = {
        item: {
            info:{},
            message: {}
        },
        private_email: [""],
        office_email: [""],
        private_phone: [""],
        office_phone: [""],
        delivery_address: [""]
    }

    componentDidMount() {
        this.init()
    }

    init = async () => {
        const itemId = this.props.itemId
        const companyId = this.props.companyId
        await this.props.company.getCompanies()
        await this.props.manager.getTags()
        
        if(!_.isUndefined(companyId)) { 
             this.setState({
                 item: {
                    company_id: companyId,
                    info:{},
                    message:{}
                 }
            })
        }
        
        if(!_.isUndefined(itemId)) {
            const data = await this.props.item.getItem(this.props.itemId)
            if(!_.isNull(data)){
                const item = data||{}
                const company = data.company||{}
                this.setState({
                    item: item,
                    company: company, 
                    private_email: item.message.private_email||[""],
                    office_email: item.message.office_email||[""],
                    private_phone: item.message.private_phone||[""],
                    office_phone: item.message.office_phone||[""],
                    delivery_address: item.message.delivery_address||["" ],
                })
            }
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, fieldsValue) => {
            if (!err) {
                const values = {
                    ...fieldsValue,
                }

                if(!_.isNull(fieldsValue["info"]['birthday'])) {
                    values["info"]["birthday"] = fieldsValue["info"]['birthday'].format('YYYY-MM-DD')
                } else {
                    values["info"]["birthday"] = ""
                }

                if(!_.isNull(fieldsValue["info"]['birthmonth'])) {
                    values["info"]["birthmonth"] = fieldsValue["info"]["info"]['birthmonth'].format('YYYY-MM')
                } else {
                    values["info"]["birthmonth"] = ""
                }

                if(!_.isUndefined(this.props.itemId)) {
                    this.props.item.updateItem(this.props.itemId, {item: values})

                } else {
                    this.props.item.createItem({item: values})
                }

                history.push("/dashboard/items")
            }
        });
    }

    handleFilter = (input, option) => {
        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    addPrivateEmail = () => {
        this.setState({
            private_email: [...this.state.private_email, ""]
        })
    }

    removePrivateEmail = (index) => {
        this.setState({
            private_email: this.state.private_email.filter((_, i) => i !== index)
        })
    }

    addOfficeEmail = () => {
        this.setState({
            office_email: [...this.state.office_email, ""]
        })
    }

    removeOfficeEmail = (index) => {
        this.setState({
            office_email: this.state.office_email.filter((_, i) => i !== index)
        })
    }

    addPrivatePhone = () => {
        this.setState({
            private_phone: [...this.state.private_phone, ""]
        })
    }

    removePrivatePhone = (index) => {
        this.setState({
            private_phone: this.state.private_phone.filter((_, i) => i !== index)
        })
    }

    addOfficePhone = () => {
        this.setState({
            office_phone: [...this.state.office_phone, ""]
        })
    }

    removeOfficePhone = (index) => {
        this.setState({
            office_phone: this.state.office_phone.filter((_, i) => i !== index)
        })
    }

    addDeliveryAddress = () => {
        this.setState({
            delivery_address: [...this.state.delivery_address, ""]
        })
    }

    removeDeliveryAddress = (index) => {
        this.setState({
            private_phone: this.state.delivery_address.filter((_, i) => i !== index)
        })
    }

    handleChange = (value) => {
        this.setState({ value });
    }

    handleBack = (e) => {
        e.preventDefault();
        history.push("/dashboard/items")
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { companies }  = this.props.company
        const { tags }  = this.props.manager
        const { item, private_phone, office_phone, delivery_address, private_email, office_email } = this.state

        return (
            <Card style={{width: "900px"}}>
                <Form onSubmit={this.handleSubmit} 
                    style={{ marginTop: 20 }}
                    autoComplete="off"
                >
                    <Divider orientation="left">基本信息</Divider>
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 15 }}
                        label="公司"
                        style={{ marginBottom: 10 }}
                    >
                        {getFieldDecorator('company_id', {
                            rules: [{
                                required: true, message: '请选择公司',
                            }],
                            initialValue: parseInt(item.company_id)||""
                        })(
                            <Select
                                showSearch
                                filterOption={this.handleFilter}
                                maxTagCount={60}
                                size={"large"}
                                optionLabelProp="label"
                            >
                                {companies.map(company=>(
                                    <Option key={company.id} value={company.id} label={company.info["name"]}>
                                        <div className="item-select">
                                            <span className="label">{company.info["name"]}</span>
                                            <span className="caption">{company.category["name"]}</span>
                                        </div>
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </FormItem>
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="职位"
                        style={{ marginBottom: 10 }}
                    >
                        {getFieldDecorator('info[job]', {
                            initialValue: item.info.job||""
                        })(<Input placeholder="请输入职位" />)}
                    </FormItem>
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="细分部门"
                        style={{ marginBottom: 10 }}
                    >
                        {getFieldDecorator('info[subdivision]', {
                            initialValue: item.info.subdivision||""
                        })(<Input placeholder="请输入细分部门" />)}
                    </FormItem>
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="姓名"
                        help={"中文/英文, /为分割符"}
                        style={{ marginBottom: 10 }}
                    >
                        {getFieldDecorator('info[name]', {
                            rules: [{
                                required: true, message: '请输入姓名',
                            }],
                            initialValue: item.info.name||""
                        })(<Input placeholder="请输入姓名 中文/英文, /为分割符" />)}
                    </FormItem>
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="性别"
                        style={{ marginBottom: 10 }}
                    >
                        {getFieldDecorator('info[sex]', {
                            initialValue: item.info.sex||""
                        })(
                            <RadioGroup >
                                <Radio value="men">男</Radio>
                                <Radio value="women">女</Radio>
                            </RadioGroup>
                        )}
                    </FormItem>
                    <Divider orientation="left">联系信息</Divider>
                    <Form.Item
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 20 }}
                        label="私人邮箱"
                        style={{ marginBottom: 10 }}
                    >
                        {
                            private_email.map((item, index) => (
                                <Form.Item
                                    required={false}
                                    key={index}
                                    style={{ marginBottom: 5 }}
                                >
                                    {getFieldDecorator(`message[private_email][${index}]`, {
                                        initialValue: private_email[index] || ""
                                    })(
                                        <Input placeholder="私人邮箱" style={{ width: '60%', marginRight: 8 }} />
                                    )}
                                    {private_email.length > 1 ? (
                                        <Icon
                                            className="dynamic-delete-button"
                                            type="minus-circle-o"
                                            disabled={private_email.length === 1}
                                            style={{ marginRight: 8 }}
                                            onClick={() => this.removePrivateEmail(index)}
                                        />
                                    ) : null}
                                    {
                                        private_email.length === (index+1) ? (
                                            <Icon
                                                className="dynamic-delete-button"
                                                type="plus-circle"
                                                onClick={() => this.addPrivateEmail()}
                                            />
                                        ) : null
                                    }
                                </Form.Item>
                            ))
                        }
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 20 }}
                        label="公司邮箱"
                        style={{ marginBottom: 10 }}
                    >
                        {
                            office_email.map((item, index) => (
                                <Form.Item
                                    required={false}
                                    key={index}
                                    style={{ marginBottom: 5 }}
                                >
                                    {getFieldDecorator(`message[office_email][${index}]`, {
                                        initialValue: office_email[index] || ""
                                    })(
                                        <Input placeholder="公司邮箱" style={{ width: '60%', marginRight: 8 }} />
                                    )}
                                    {office_email.length > 1 ? (
                                        <Icon
                                            className="dynamic-delete-button"
                                            type="minus-circle-o"
                                            disabled={office_email.length === 1}
                                            style={{ marginRight: 8 }}
                                            onClick={() => this.removeOfficeEmail(index)}
                                        />
                                    ) : null}
                                    {
                                        office_email.length === (index+1) ? (
                                            <Icon
                                                className="dynamic-delete-button"
                                                type="plus-circle"
                                                onClick={() => this.addOfficeEmail()}
                                            />
                                        ) : null
                                    }
                                </Form.Item>
                            ))
                        }
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 20 }}
                        label="私人电话"
                        style={{ marginBottom: 10 }}
                    >
                        {
                            private_phone.map((item, index) => (
                                <Form.Item
                                    required={false}
                                    key={index}
                                    style={{ marginBottom: 5 }}
                                >
                                    {getFieldDecorator(`message[private_phone][${index}]`, {
                                        initialValue: private_phone[index] || ""
                                    })(
                                        <Input placeholder="私人电话" style={{ width: '60%', marginRight: 8 }} />
                                    )}
                                    {private_phone.length > 1 ? (
                                        <Icon
                                            className="dynamic-delete-button"
                                            type="minus-circle-o"
                                            disabled={private_phone.length === 1}
                                            style={{ marginRight: 8 }}
                                            onClick={() => this.removePrivatePhone(index)}
                                        />
                                    ) : null}
                                    {
                                        private_phone.length === (index+1) ? (
                                            <Icon
                                                className="dynamic-delete-button"
                                                type="plus-circle"
                                                onClick={() => this.addPrivatePhone()}
                                            />
                                        ) : null
                                    }
                                </Form.Item>
                            ))
                        }
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 20 }}
                        label="公司电话"
                        style={{ marginBottom: 10 }}
                    >
                        {
                            office_phone.map((item, index) => (
                                <Form.Item
                                    required={false}
                                    key={index}
                                    style={{ marginBottom: 5 }}
                                >
                                    {getFieldDecorator(`message[office_phone][${index}]`, {
                                        initialValue: item||""
                                    })(
                                        <Input placeholder="公司电话" style={{ width: '60%', marginRight: 8 }} />
                                    )}
                                    {
                                        office_phone.length > 1 ? (
                                        <Icon
                                            className="dynamic-delete-button"
                                            type="minus-circle-o"
                                            disabled={private_phone.length === 1}
                                            style={{ marginRight: 8 }}
                                            onClick={() => this.removeOfficePhone(index)}
                                        />
                                    ) : null
                                    }
                                    {
                                        office_phone.length === (index+1) ? (
                                            <Icon
                                                className="dynamic-delete-button"
                                                type="plus-circle"
                                                onClick={() => this.addOfficePhone()}
                                            />
                                        ) : null
                                    }
                                </Form.Item>
                            ))
                        }
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 20 }}
                        label="快递地址"
                        style={{ marginBottom: 10 }}
                    >
                        {
                            delivery_address.map((item, index) => (
                                <Form.Item
                                    required={false}
                                    key={index}
                                    style={{ marginBottom: 5 }}
                                >
                                    {getFieldDecorator(`message[delivery_address][${index}]`, {
                                        initialValue: item||""
                                    })(
                                        <Input placeholder="快递地址" style={{ width: '60%', marginRight: 8 }} />
                                    )}
                                    {
                                        delivery_address.length > 1 ? (
                                            <Icon
                                                className="dynamic-delete-button"
                                                type="minus-circle-o"
                                                disabled={private_phone.length === 1}
                                                style={{ marginRight: 8 }}
                                                onClick={() => this.removeDeliveryAddress(index)}
                                            />
                                        ) : null
                                    }
                                    {
                                        delivery_address.length === (index+1) ? (
                                            <Icon
                                                className="dynamic-delete-button"
                                                type="plus-circle"
                                                onClick={() => this.addDeliveryAddress()}
                                            />
                                        ) : null
                                    }
                                </Form.Item>
                            ))
                        }
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="qq"
                        style={{ marginBottom: 10 }}
                    >
                        {getFieldDecorator('info[qq]', {
                            initialValue: item.info.qq||""
                        })(
                            <Input placeholder="请输入qq" />
                        )}
                    </Form.Item>
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="微信"
                        style={{ marginBottom: 10 }}
                        help={"微信名字/微信号, /为分割符"}
                    >
                        {getFieldDecorator('info[wechat]', {
                            initialValue: item.info.wechat || ""
                        })(<Input placeholder="请输入公司微信, 微信名字/微信号" />)}
                    </FormItem>
                    <Divider orientation="left">其他信息</Divider>
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="生日"
                        style={{ marginBottom: 10 }}
                    >
                        <FormItem
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 10 }}
                            label="日期"
                            style={{ marginBottom: 5 }}
                        >
                            {getFieldDecorator('info[birthday]', {
                                initialValue: item.info.birthday ? moment(item.info.birthday) : null
                            })(
                                <DatePicker />
                            )}
                        </FormItem>
                        <FormItem
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 10 }}
                            label="月份"
                        >
                            {getFieldDecorator('info[birthmonth]', {
                                initialValue: item.info.birthmonth ? moment(item.info.birthmonth) : null
                            })(
                                <MonthPicker />
                            )}
                        </FormItem>
                    </FormItem>
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 15 }}
                        label="描述"
                        style={{ marginBottom: 10 }}
                    >
                        {getFieldDecorator('info[describe]', {
                            initialValue: item.info.describe||""
                        })(
                            <Input placeholder="请输入描述" />
                        )}
                    </FormItem>
                    <Form.Item
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 10 }}
                        label="重要性"
                        style={{ marginBottom: 10 }}
                    >
                        {getFieldDecorator('info[score]', {
                            initialValue: item.info.score ? parseInt(item.info.score) : 1
                        })(
                            <Rate count={5}/>
                        )}
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 15 }}
                        label="标签"
                        style={{ marginBottom: 10 }}
                    >
                        {getFieldDecorator('message[tags]', {
                            initialValue: item.message.tags || []
                        })(
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                tokenSeparators={[',']}
                                optionLabelProp="label"
                            >
                                {tags.map(tag=>(
                                    <Option key={tag.id} value={tag.name} label={tag.name}>
                                        <div className="item-select">
                                            <span className="label">{tag.name}</span>
                                            <span className="caption">{"优先度"+tag.priority}</span>
                                        </div>
                                    </Option>
                                ))}

                            </Select>
                        )}
                    </Form.Item>
                    <FormItem
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 15 }}
                        label="备注"
                        style={{ marginBottom: 10 }}
                    >
                        {getFieldDecorator('info[remark]', {
                            initialValue: item.info.remark||""
                        })(
                            <TextArea  autosize={{ minRows: 3}} />
                        )}
                    </FormItem>
                    <FormItem
                        wrapperCol={{ span: 12, offset: 3 }}
                    >
                        <Button type="primary" htmlType="submit">提交</Button>
                        <Button style={{marginLeft: 20}} onClick={this.handleBack}>返回上一级</Button>
                    </FormItem>
                </Form>
            </Card>
        )
    }
}

export default  Form.create()(UserEditForm);