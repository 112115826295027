
import _ from 'lodash'
import { 
    message 
} from "antd";

import admin from '../store/admin'
import "antd/lib/message/style";

class ErrorService {
    handleCommonError = (response) => {
        console.log(response)
        if(_.isUndefined(response) || (response && response.status === 406) ) {
            admin.logout()
            return null;
        }

        if (response.status !== 200 && response.status !== 201 && response.status !== 202  && response.status !== 204) {
            message.error(response.data["message"], 2);
            return null;
        }
        
        if (response.status == 200 || response.status == 201 || response.status == 202  || response.status == 204) {
            if (!_.isEmpty(response.data["message"])) {
                message.success(response.data["message"], 2);
            }
            // 判断下是否含有token, 有token情况下设置token更新
        }

        return response.data.data || response.data
    }
}

export default new ErrorService()