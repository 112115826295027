import React from 'react';
import classNames from 'classnames'
import { PageHeader } from 'antd';

import TagEditForm from '../../../components/EditForm/tag'

export default class TagEdit extends React.Component {
    render() {
        return (
            <div className={classNames("minimum-content")}>
                <PageHeader
                    title="编辑分类"
                    subTitle="This is a subtitle"
                />
                <div className={classNames("content-info", "minimum-list")}>
                    <TagEditForm tagId={this.props.match.params.id}/>
                </div>
            </div>
        )
    }
}