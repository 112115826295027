import React from 'react';
import classNames from 'classnames'
import { PageHeader } from 'antd';

import TagEditForm from '../../../components/EditForm/tag'

export default class TagNew extends React.Component {
    render() {
        return (
            <div className={classNames("minimum-content")}>
                <PageHeader
                    title="新标签"
                    subTitle="This is a subtitle"
                />
                <div className={classNames("content-info", "minimum-list")}>
                    <TagEditForm />
                </div>
            </div>
        )
    }
}