import _ from 'lodash'
import React from 'react';
import { inject, observer } from 'mobx-react'

import { 
    Form, 
    Input, 
    Button, 
    Select,
    Switch,
    Card,
} from 'antd';

import history from '../../router/history'


const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

@inject('manager')
@observer
class CategoryEditForm extends React.Component {

    state = {
        category: {},
    }

    componentDidMount() {
        this.init()
    }

    init = async () => {
        const categoryId = this.props.categoryId
        if(!_.isUndefined(categoryId)) {
            const data = await this.props.manager.getCategory(categoryId)
            if(!_.isNull(data)){
                this.setState({
                    category: data,
                })
            }
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if(!_.isUndefined(this.props.categoryId)) {
                    this.props.manager.updateCategory(this.props.categoryId, values)
                } else {
                    this.props.manager.createCategory(values)
                }
            }
            history.push('/dashboard/manager/categories')
        });
    }

    handleBack = (e) => {
        e.preventDefault();
        history.push("/dashboard/manager/categories")
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const category = this.state.category

        return (
            <Card style={{width: "600px"}}>
                <Form onSubmit={this.handleSubmit} 
                    style={{ marginTop: 20 }}
                    autoComplete="off"
                >

                    {
                        !_.isUndefined(this.props.categoryId) ?
                            <FormItem
                                labelCol={{ span: 3 }}
                                wrapperCol={{ span: 10 }}
                                label="原有名称"
                            >  {category.name||""} </FormItem>
                            : 
                            ""
                    }
                        <FormItem
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 10 }}
                            label="分类"
                        >   
                            
                            {getFieldDecorator('name', {
                                initialValue: category.name||""
                            })(<Input placeholder="请输入名称" />)}
                        </FormItem>
                        <FormItem
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 10 }}
                            label="关联"
                        >   
                            {getFieldDecorator('associate', {
                                valuePropName: 'checked',
                                initialValue: category.associate||false
                            })(<Switch />)}
                        </FormItem>
                    <FormItem
                        wrapperCol={{ span: 12, offset: 3 }}
                    >
                        <Button type="primary" htmlType="submit">提交</Button>
                        <Button onClick={this.handleBack}>返回上一级</Button>
                    </FormItem>
                </Form>
            </Card>
        )
    }
}

export default  Form.create()(CategoryEditForm);