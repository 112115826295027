import _ from 'lodash'
import React from 'react';


export default class LinkDescription extends React.Component { 

    render() {
        const description = this.props.description || ""
        const re = /(http|ftp|https):\/\/[\w-]+(.[\w-]+)+([\w-.,@?^=%&:/~+#]*[\w-\@?^=%&/~+#])?/g

        return (
            <span dangerouslySetInnerHTML={{ __html: description.replace(re,e=>(`<a href=${e} target="_blank">${e}</a>`))}}/>
        )
    }
}