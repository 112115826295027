import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'

import { 
    Avatar,
    PageHeader,
    List,
    Card,
    Tag
 } from 'antd'

import ManagerCompanyCard from '../../../components/ShowCard/ManagerCompanyCard'
import CompanyItems from '../../../components/ListTable/company_items'

@inject('item', 'company')
@observer
export default class CompanyShow extends React.Component {

    state = {
        data: {},
        logs: []
    }

    componentDidMount() {
        this.init()
    }

    handleRollBackClick = () => {

    }

    handleDeleteClick = () => {
        
    }

    init = async () => {
        const companyId = this.props.match.params.id
        const data = await this.props.company.getCompanyLogs(companyId)
        console.log(data)
        this.setState({
            logs: data
        })
    }

    render() {
        const { data, logs } = this.state

        const get_tag = (type) => {
            switch(type) {
                case "create_type":
                    return <Tag>创建</Tag>
                case "update_type":
                    return <Tag>修改</Tag>
                case "destroy_type":
                    return <Tag>删除</Tag>
                case "auth_type":
                    return <Tag>权限</Tag>
            }
        }

        return (
            <div className={classNames("small-content")}>
                <PageHeader
                    title="公司历史记录"
                    subTitle="改动历史记录"
                />
                <div className={classNames("content-info", "small-list")}>
                    <Card >
                        <List
                            itemLayout="horizontal"
                            dataSource={logs}
                            renderItem={log => (
                            <List.Item
                            
                                actions={[
                                    <a href='javascript:;' onClick={(e)=>this.handleRollBackClick(e, log.id)}>回滚</a>,
                                    <a href='javascript:;' onClick={(e)=>this.handleDeleteClick(e, log.id)}>删除</a>
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                                    title={
                                        <div>
                                            <span>{get_tag(log.log_type)}</span>
                                            <span style={{margin: 5}}>{log.user.nickname}</span>
                                            <br/>
                                            <span>{moment(log.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                                        </div>
                                        
                                        }
                                    description={
                                        <div>
                                            {
                                                Object.keys(log.change_info).map(key=>{
                                                    return (
                                                        <div key={key}>
                                                            <div><span>{key}</span></div>
                                                            <span>{log.change_info[key][0].toString()}</span> =>
                                                            <span>{log.change_info[key][1].toString()}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                />
                            </List.Item>
                            )}
                        />
                    </Card>
                </div>
            </div>
        )
    }
}