import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'

import　history from '../../../router/history'

import { 
    Card, 
    List,
    PageHeader,
    Button,
    Descriptions
} from 'antd';

import RecordDate from '../../../components/LinkTag/record_date'
import UserTag from '../../../components/LinkTag/user'
import ItemTag from '../../../components/LinkTag/item'

@inject('record')
@observer
export default class RecordList extends React.Component {

    componentDidMount() {
        this.props.record.getRecords()
    }

    handleNewClick = (e) => {
        e.preventDefault()
        history.push("/dashboard/records_new")
    }

    handleEditClick =  async (e, key) => {
        e.preventDefault();
        history.push(`/dashboard/records/${key}/edit`)
    }

    handleShowClick = (e, key) => {
        e.preventDefault();
        history.push(`/dashboard/records/${key}`)
    }

    render() {
        const records = this.props.record.records

        return (
            <div className={classNames("small-content")}>
                <PageHeader
                    title="记录列表"
                    subTitle="属于我的记录"
                />
                <div className={classNames("content-info", "small-list")}>
                    <Card>
                        <div>
                            <Button icon="plus" type="primary" onClick={this.handleNewClick}>
                                新建
                            </Button>
                        </div>
                        <div  style={{marginTop:"10px"}}>
                            <List
                                className={"record-list"}
                                itemLayout="vertical"
                                dataSource={records}
                                pagination={{ pageSize: 600 }}
                                renderItem={record => {
                                const users = record.users|| []
                                return (
                                    <List.Item
                                        key={record.id}
                                        actions={[
                                            <a href='javascript:;' onClick={(e)=>this.handleShowClick(e, record.id)}>详细</a>,
                                            <a href='javascript:;' onClick={(e)=>this.handleEditClick(e, record.id)}>编辑</a>,
                                        ]}
                                    >
                                        <List.Item.Meta 
                                            title={
                                                <div>
                                                    <div className={"record_items"}>
                                                        {
                                                            record.items.map(item=>(<ItemTag key={item.id} item={item}/>))
                                                        }
                                                    </div>
                                                    <div className={"record_users"}>
                                                        {users.map(user=>(<UserTag  key={user.id} user={user} />))}
                                                    </div>
                                                </div>
                                            }
                                            description={
                                                <RecordDate record_date={record.record_date} record_message={record.message} />
                                            }
                                        />
                                        <div>
                                            <Descriptions column={1}>
                                                <Descriptions.Item label="沟通目的" ><pre><span dangerouslySetInnerHTML={{ __html: record.message["purpose"]}}/></pre></Descriptions.Item>
                                                <Descriptions.Item label="沟通准备" ><pre><span dangerouslySetInnerHTML={{ __html: record.message["prepare"]}}/></pre></Descriptions.Item>
                                                <Descriptions.Item label="沟通记录" ><pre><span dangerouslySetInnerHTML={{ __html: record.message["content"]}}/></pre></Descriptions.Item>
                                                {
                                                    record.message["next_time"] ? <Descriptions.Item label="计划下次拜访">{moment(record.message["next_time"]).format('YYYY-MM-DD')} - {moment(record.message["next_time"]).fromNow()}</Descriptions.Item> : "11"
                                                }
                                                {
                                                    record.message["plan"] ? <Descriptions.Item label="计划和反馈"><pre><span dangerouslySetInnerHTML={{ __html: record.message["plan"]}}/></pre></Descriptions.Item> : ""
                                                }
                                            </Descriptions>
                                        </div>
                                    </List.Item>
                                )}}
                            />
                            共{records.length||0}条记录
                        </div>
                    </Card>
                </div>
            </div>
        )
    }
}