import React from 'react';
import { inject, observer } from 'mobx-react'
import classNames from 'classnames';
import { 
    Avatar,
    Layout,
    Menu,
    Icon,
} from 'antd';


import styles from './SiderMenu.module.less';
import history from '../../router/history'
import BaseMenu from './BaseMenu'

const { Sider } = Layout;

@inject('admin')
@observer
export default class SiderMenu extends React.Component {

    state = {
        collapsed: false,
    };

    onCollapse = (collapsed) => {
        this.setState({ collapsed });
    }

    setting = () => {
        history.push("/dashboard/users/setting")
    }

    logout = () => {
        this.props.admin.logout()
        history.push("/login")
    }

    render() {
        const { logo } = this.props;

        const avatar = _.isEmpty(this.props.admin.user.avatar) 
            ? "https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png"
            : this.props.admin.avatar

        const nickname = _.isEmpty(this.props.admin.user.nickname) 
            ? "无名氏"
            : this.props.admin.user["nickname"]

        return(
            <Sider
                theme={"light"}
                className={classNames(styles.sider)}
                style={{
                overflow: 'auto',
                height: '100%',
                position: 'fixed',
                left: 0,
                }}
            >
        <div className={styles.item} id="logo">
            <img src={logo} alt="logo" />
            <h1>{"idaily"}</h1>
        </div>
        <div id="user-avatar" className={classNames(styles.item)}>
            {/* <a href='javascript:;' onClick={()=>this.setting()}> */}
                <Avatar className="avatar" src={avatar} />
                <h3>{nickname}</h3>
            {/* </a> */}
        </div>
        <BaseMenu
            mode="inline"
            style={{ padding: '16px 0', width: '100%' }}
        />

        <div className={classNames(styles.item, styles.action)} id="logout">
            <a href='javascript:;' onClick={()=>this.logout()}>
                <Icon type="logout" className={"icon"} ></Icon>
                <h2>{"退出"}</h2>
            </a>
        </div>
    </Sider>
        )
    }
}