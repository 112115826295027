import _ from 'lodash'
import React from 'react';
import moment from 'moment'

import { 
    Card,
    Divider,
    Button
} from 'antd';

import history from '../../router/history'
import LinkDescription from './LinkDescription'

import { DescriptionList } from 'ant-design-pro';
const { Description } = DescriptionList;

export default class ManagerCompanyCard extends React.Component {

    handleHistory = () => {
        if(!_.isEmpty(this.props.data)) {
            history.push("/dashboard/manager/companies/"+this.props.data.id+"/logs")
        }
    }

    render() {
        const company = this.props.data
        const info = this.props.data.info || {}
        const category = this.props.data.category || {}
        const associate = this.props.data.associate || {}

        return (
            <div style={{width: 500}}>
                <Card>
                    <DescriptionList size="large" title="基础信息" col={1}>
                        <Description term="公司名称"><span>{info.name||""}</span></Description>
                        <Description term="公司分类">{category.name||""}</Description>
                        <Description term="关联公司">{}</Description>
                    </DescriptionList>
                    <Divider />
                    <DescriptionList size="large" title="联系方式" col={1}>
                        <Description term="创始年份">{info.founding_year||""}</Description>
                        <Description term="国家">{info.country||""}</Description>
                        <Description term="微博"><LinkDescription description={info.weibo} /></Description>
                        <Description term="PR邮箱">{info.pr_email||""}</Description>
                        <Description term="公司微信">{info.wechat||""}</Description>
                        <Description term="Instagram"><LinkDescription description={info.ins} /></Description>
                        <Description term="官网"><LinkDescription description={info.url} /></Description>
                        <Description term="备注"><LinkDescription description={info.remark} /></Description>
                    </DescriptionList>
                    <Divider />
                    <DescriptionList title="" col={1}>
                        <Description term="创建时间">{moment(company.created_at).format("YYYY-MM-DD hh:mm:ss")}</Description>
                        <Description term="更新时间">{moment(company.updated_at).format("YYYY-MM-DD hh:mm:ss")}</Description>
                    </DescriptionList>
                    <Divider />
                    <DescriptionList size="large" col={2}>
                        <Description>
                            <Button onClick={this.handleHistory}>历史版本</Button>
                        </Description>
                    </DescriptionList>
                </Card>
            </div>
        )
    }
}
