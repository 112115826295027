import Init from '../layout/Init'

import BasicLayout from '../layout/BasicLayout'

import Login from '../layout/Login'

import IndexLayout from '../layout/IndexLayout'

import Info from '../pages/board/Info'

import ItemList from '../pages/board/Item/list'
import ItemEdit from '../pages/board/Item/edit'
import ItemNew from '../pages/board/Item/new'
import ItemShow from '../pages/board/Item/show'
import ItemAllList from '../pages/board/Item/all_list'
import ItemSearch from '../pages/board/Item/search'


import RecordNew from '../pages/board/Record/new'
import RecordEdit from '../pages/board/Record/edit'
import RecordList from '../pages/board/Record/list'
import RecordShow from '../pages/board/Record/show'

import CompanyNew from '../pages/board/Company/new'
import CompanyEdit from '../pages/board/Company/edit'
import CompanyList from '../pages/board/Company/list'
import CompanyShow from '../pages/board/Company/show'
// import CompanyAllList from '../pages/Company/all_list'

import Categorylist from '../pages/admin/Category/list'
import CategoryNew from '../pages/admin/Category/new'
import CategoryEdit from '../pages/admin/Category/edit'

import Taglist from '../pages/admin/Tag/list'
import TagNew from '../pages/admin/Tag/new'
import TagEdit from '../pages/admin/Tag/edit'

import AuthList from '../pages/auth/auth_list'
import ItemExport from '../pages/auth/export'
import ManagerExport from '../pages/admin/Item/export'



import ManagerCompanyList from '../pages/admin/Company/list'
import ManagerCompanyShow from '../pages/admin/Company/show'
import ManagerCompanyLogs from '../pages/admin/Company/logs'

import ManagerItemsList from '../pages/admin/Item/list'
import ManagerItemsShow from '../pages/admin/Item/show'
import ManagerItemsLogs from '../pages/admin/Item/logs'

import ManagerRecordsList from '../pages/admin/Record/list'
import ManagerRecordsShow from '../pages/admin/Record/show'

const routes = [
    {
        path: "/",
        exact: true,
        component: Init,
    },
    {
        path: "/login",
        exact: true,
        component: Login,
    },
    {
        path: "/dashboard",
        private: true,
        component: BasicLayout,
        routes: [
            {
                path: "/dashboard",
                private: true,
                exact: true,
                component: Info,
            },
            {
                path: "/dashboard/manager_records",
                private: true,
                exact: true,
                component: ManagerRecordsList,
            },
            {
                path: "/dashboard/export",
                private: true,
                exact: true,
                component: ItemExport,
            },
            {
                path: "/dashboard/manager_export",
                private: true,
                exact: true,
                component: ManagerExport,
            },
            {
                path: "/dashboard/search_items",
                private: true,
                exact: true,
                component: ItemSearch,
            },
            {
                path: "/dashboard/all_items",
                private: true,
                exact: true,
                component: ItemAllList,
            },


            {
                path: "/dashboard/manager",
                component: IndexLayout,
                routes: [
                    {
                        path: "/dashboard/manager/companies",
                        component: IndexLayout,
                        routes: [
                            {
                                path: "/dashboard/manager/companies",
                                private: true,
                                exact: true,
                                component: ManagerCompanyList,
                            },
                            {
                                path: "/dashboard/manager/companies/:id",
                                private: true,
                                exact: true,
                                component: ManagerCompanyShow,
                            },
                            {
                                path: "/dashboard/manager/companies/:id/logs",
                                private: true,
                                exact: true,
                                component: ManagerCompanyLogs,
                            },
                        ]
                    },
                    {
                        path: "/dashboard/manager/items",
                        component: IndexLayout,
                        routes: [
                            {
                                path: "/dashboard/manager/items",
                                private: true,
                                exact: true,
                                component: ManagerItemsList,
                            },
                            {
                                path: "/dashboard/manager/items/:id",
                                private: true,
                                exact: true,
                                component: ManagerItemsShow,
                            },
                            {
                                path: "/dashboard/manager/items/:id/logs",
                                private: true,
                                exact: true,
                                component: ManagerItemsLogs,
                            },
                        ]
                    },
                    {
                        path: "/dashboard/manager/records",
                        component: IndexLayout,
                        routes: [
                            {
                                path: "/dashboard/manager/records",
                                private: true,
                                exact: true,
                                component: ManagerRecordsList,
                            },
                            {
                                path: "/dashboard/manager/records/:id",
                                private: true,
                                exact: true,
                                component: ManagerRecordsShow,
                            },
                        ]
                    },
                    {
                        path: "/dashboard/manager/categories_new",
                        private: true,
                        exact: true,
                        component: CategoryNew,
                    },
                    {
                        path: "/dashboard/manager/categories",
                        component: IndexLayout,
                        routes: [
                            {
                                path: "/dashboard/manager/categories/:id/edit",
                                private: true,
                                exact: true,
                                component: CategoryEdit,
                            },
                            {
                                path: "/dashboard/manager/categories",
                                private: true,
                                exact: true,
                                component: Categorylist,
                            },
                        ]
                    },
                    {
                        path: "/dashboard/manager/tags_new",
                        private: true,
                        exact: true,
                        component: TagNew,
                    },
                    {
                        path: "/dashboard/manager/tags",
                        component: IndexLayout,
                        routes: [
                            {
                                path: "/dashboard/manager/tags/:id/edit",
                                private: true,
                                exact: true,
                                component: TagEdit,
                            },
                            {
                                path: "/dashboard/manager/tags",
                                private: true,
                                exact: true,
                                component: Taglist,
                            },
                        ]
                    },
                ]
            },
            {
                path: "/dashboard/items_new",
                private: true,
                exact: true,
                component: ItemNew,
            },
            {
                path: "/dashboard/companies_new",
                private: true,
                exact: true,
                component: CompanyNew,
            },
            {
                path: "/dashboard/auth_list",
                private: true,
                exact: true,
                component: AuthList,
            },


            {
                path: "/dashboard/companies",
                component: IndexLayout,
                routes: [
                    {
                        path: "/dashboard/companies/:id/edit",
                        private: true,
                        exact: true,
                        component: CompanyEdit,
                    },
                    {
                        path: "/dashboard/companies",
                        private: true,
                        exact: true,
                        component: CompanyList,
                    },
                    {
                        path: "/dashboard/companies/:id",
                        private: true,
                        exact: true,
                        component: CompanyShow,
                    },
                ]
            },
            {
                path: "/dashboard/items",
                component: IndexLayout,
                routes: [

                    {
                        path: "/dashboard/items/:id/edit",
                        private: true,
                        exact: true,
                        component: ItemEdit,
                    },

                    {
                        path: "/dashboard/items",
                        private: true,
                        exact: true,
                        component: ItemList,
                    },
                    {
                        path: "/dashboard/items/:id",
                        private: true,
                        exact: true,
                        component: ItemShow,
                    },
                ]
            },
            {
                path: "/dashboard/records_new",
                private: true,
                exact: true,
                component: RecordNew,
            },
            {
                path: "/dashboard/records",
                component: IndexLayout,
                routes: [
                    {
                        path: "/dashboard/records/:id/edit",
                        private: true,
                        exact: true,
                        component: RecordEdit,
                    },
                    {
                        path: "/dashboard/records",
                        private: true,
                        exact: true,
                        component: RecordList,
                    },
                    {
                        path: "/dashboard/records/:id",
                        private: true,
                        exact: true,
                        component: RecordShow,
                    },
                ]
            },
        ]
    }
];

export default routes;