import config from "./index"

const api = {
    board: {
        user: config.MISO_URL + "/board/users",
        company: config.SERVER_URL + "/board/companies",
        category: config.SERVER_URL + "/board/categories",
        item: config.SERVER_URL + "/board/items",
        record: config.SERVER_URL + "/board/records",
        tag: config.SERVER_URL + "/board/tags",
        comment: config.SERVER_URL + "/board/comments",
    },
    admin: {
        item: config.SERVER_URL + "/admin/items",
        category: config.SERVER_URL + "/admin/categories",
        company: config.SERVER_URL + "/admin/companies",
        record: config.SERVER_URL + "/admin/records",
        tag: config.SERVER_URL + "/admin/tags",
        comment: config.SERVER_URL + "/admin/comments",
    },
    auth_list: {
        item: config.SERVER_URL + "/auth/items",
    },

    auth: config.SERVER_URL + "/authentication",
    item: config.SERVER_URL + "/items",
    record: config.SERVER_URL + "/records",
    company: config.SERVER_URL + "/companies",
    category: config.SERVER_URL + "/categories",
    comment: config.SERVER_URL + "/comments"
}

export default api;