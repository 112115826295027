import React from 'react';
import moment from 'moment'
import { inject, observer } from 'mobx-react'

import {
  Comment, Avatar, Form, Button, List, Input, Card, Tooltip
} from 'antd';
const TextArea = Input.TextArea;

const CommentList = ({ comments, handleDelete, user_id, isAuth }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
    itemLayout="horizontal"
    renderItem={comment => {
        const created_time = comment.created_at
        const user = comment.info.user
        return (
            <Comment
                author={user.nickname}
                avatar={(
                    <Avatar
                        src={user.avatar}
                        alt={user.nickname}
                    />
                )}
                datetime={(
                    <Tooltip title={moment(created_time).format('YYYY-MM-DD HH:mm:ss')}>
                        <span>{moment(created_time).fromNow()}</span>
                    </Tooltip>
                )}
                content={(
                    <p>{comment.comment}</p>
                )}

                actions={[ user_id == comment.user_id || isAuth("inspect") ? <a href='javascript:;' onClick={(e)=>handleDelete(e, comment.id)}>删除</a> : ""]} 
            />
        )
    }}
  />
);


const Editor = ({
    onChange, onSubmit, submitting, value,
}) => (
    <div>
        <Form.Item>
            <TextArea rows={4} onChange={onChange} value={value} />
        </Form.Item>
        <Form.Item>
            <Button
                htmlType="submit"
                loading={submitting}
                onClick={onSubmit}
                type="primary"
            >
                添加评论
            </Button>
        </Form.Item>
    </div>
);

@inject('admin', 'comment')
@observer
export default class CommentsList extends React.Component {

    state = {
        submitting: false,
        value: '',
    }

    handleChange = (e) => {
        this.setState({
            value: e.target.value,
        });
    }

    componentDidMount() {
        const item_id = this.props.itemId
        this.props.comment.getComments(item_id)
    }

    handleDelete = (e, key) => {
        e.preventDefault();
        this.props.comment.destroyComment(key)
    }

    handleSubmit = async () => {
        if (!this.state.value) {
            return;
        }

        this.setState({
            submitting: true,
        });
        
        const data = await this.props.comment.createComment({item_id:this.props.itemId, comment: this.state.value})
        this.setState({
            submitting: false,
        });
    }

    render() {
        const { submitting, value } = this.state;
        const { user } = this.props.admin
        const { comments } = this.props.comment

        return (
            <div>
                <Card bordered={false}>
                    {comments.length > 0 && <CommentList 
                                                comments={comments} 
                                                handleDelete={this.handleDelete} 
                                                user_id={this.props.admin.user.id} 
                                                isAuth={this.props.admin.isAuth}
                                            />}
                    <Comment
                        avatar={(
                            <Avatar
                                src={user.avatar}
                                alt={user.nickname}
                            />
                        )}
                        content={(
                            <Editor
                                onChange={this.handleChange}
                                onSubmit={this.handleSubmit}
                                submitting={submitting}
                                value={value}
                            />
                        )}
                    />
                </Card>
            </div>
        )
    }
}